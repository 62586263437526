/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.PageWarnings {
    padding-block-start: env(safe-area-inset-top);
    background-color: #FFF5DC;
    color: $white;
    display: flex;
    font-size: 14px;
    height: 38px;
    inset-block-start: 0;
    width: 100%;
    z-index: 110;

    &-Wrapper {
        margin: 0 auto;
        width: 100%;
        max-width: 1400px;
        padding: 0 32px;
    }

    &-Content {
        padding-top: 8px;
        align-items: center;
        justify-content: center;
        margin: 0;
        height: 100%;
        float: left;
    }

    &-Close {
        margin-top: 4px;
        z-index: 120;
        float: right;
        
        .CloseIcon {
            width: 30px;
            height: 30px;
        }
    }

    &-Ico {
        background: transparent
                        url("../../style/icons/warning.svg") center no-repeat;
        background-size: cover;
        margin-right: 10px;
        margin-top: 9px;
        float: left;
        width: 18px;
        height: 18px;
        z-index: 120;
    }

    a {
        font-size: 14px;
        font-weight: normal;
        color: #000;

        &:hover {
            text-decoration: underline;
        }
    }
}