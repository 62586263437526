/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --menu-item-height: 20px;
    --menu-total-height: 59px;
}

@mixin subcategory-visible {
    pointer-events: all;
    z-index: 10;
    opacity: 1;
    transform: translateX(0);
}

.Menu {
    &-ItemList {
        width: 100%;
        max-width: 1384px;
        padding: 0 32px;
        margin: 0 auto;
        @include flex(null, null, center, null);
        justify-content: space-between !important;
        @include tablet {
            padding: 0 16px;
        }
        @include mobile {
            flex-direction: column;
            padding: 12px 20px 0;
        }
    }

    &-SubItemWrapper {
        @include mobile {
            margin-bottom: 6px;
            border-bottom: 1px solid $color-gray4;
            width: 90%;
        }
    }

    &-CompareLink {
        color: var(--color-black);
        font-size: $font-size-p2;
        font-weight: $font-weight-light;
        text-transform: none;
        @include flex($align: center);

        .CompareIcon {
            margin-inline-end: 12px;

            &:hover {
                fill: var(--color-black);
            }
        }

        &Wrapper {
            border-block-end: 1px solid var(--primary-divider-color);
            padding-block-start: 17px;
            padding-block-end: 16px;
            padding-inline: 0;
            margin: 0 16px;

            @include mobile {
                padding: 13px 0;
            }
        }
    }

    &-ExpandedState {
        --plus-minus-icon-size: 18px;
        display: inline-block;
        float: inline-end;

        &:dir(rtl) {
            float: inline-start;
        }

        position: absolute;
        inset-inline-end: -24px;

        @include desktop {
            display: none;
        }
    }

    &-SubCatLink {
        @include mobile {
            width: 100%;
        }
    }

    &-Item {
        padding-inline-start: 0;
        @include desktop {
            margin: 0 !important;
            text-align: center;
            &:hover {
                cursor: pointer;
                background: $color-blue2;
            }
        }

        @include mobile {
            width: 100%;
        }

        &::before {
            content: none;
        }

        &Caption {
            color: var(--color-black);

            &:hover {
                color: $white;
            }

            @include mobile {
                margin-left: 34px;
                margin-right: 34px !important;
                text-align: center;
                line-height: 1.1;

                &:hover {
                    color: var(--color-black);
                }
            }

            @include mobile {
                padding-block: 12px;
            }

            &_isSecondLevel {
                font-weight: 500;
            }

            &_type {
                &_main {
                    @include desktop {
                        margin: 0;
                        padding: 19px 16px;
                    }

                    @include tablet {
                        padding: 26px 10px;
                    }

                    text-transform: uppercase;
                    font-weight: 700;
                    line-height: 20px;
                }

                &_subcategory {
                    line-height: 20px;

                    @include mobile {
                        margin-inline-end: 0;
                    }

                    @include desktop {
                        display: inline-block;

                        &:hover {
                            color: var(--link-hover);
                        }
                    }
                }
            }
        }

        &List {
            @include desktop {
                @include flex(null, null, null, center);

                .Menu-SubMenu & {
                    display: block;
                }
            }

            &_type_subcategory {
                @include mobile {
                    padding-block: 8px;
                }
            }
        }

        &:not(:first-of-type) {
            @include desktop {
                margin-inline-start: 20px;
            }
        }
    }

    &-Link {
        display: contents;
        font-weight: $font-weight-light;
        margin-block: 8px;
        margin-inline: 0 16px;
    }

    &-MainCategories {
        background: $white;
        position: relative;
        @include mobile {
            margin-top: 90px;
        }
        @include desktop {
            .Menu-Link {
                font-weight: 700;

                &:hover {
                    color: $white !important;
                }
            }
        }

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            bottom: 1px;
            z-index: -1;
            box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.3);
        }
    }

    &-SubCategories {
        @include desktop {
            padding-inline: 32px;
            padding-block-end: 8px;
        }
    }

    &-Sub {
        &ItemWrapper {
            > .Menu-Link {
                @include desktop {
                    font-weight: 700;
                }
            }

            break-inside: avoid;
            overflow: hidden;
        }

        &Menu {
            display: none;

            &_isVisible {
                background: $color-gray3;
                display: block;
                padding-inline: 16px;
            }

            @include desktop {
                background: $white;
                display: block;
                padding: 0;
            }

            .Menu-SubMenu & {
                padding-block-end: 0;
            }

            .Menu-ItemCaption {
                @include mobile {
                    padding-block: 12px;
                }

                @include desktop {
                    padding-block-end: 8px;
                }
            }

            > .Menu-ItemList {
                @include desktop {
                    padding: 20px 45px;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(4, 1fr);
                    grid-auto-flow: column;
                    grid-column-gap: 20px;
                }
            }
        }
    }

    &-SubMenu {
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            bottom: 0;
            top: 0;
            z-index: -1;
            box-shadow: 0 3px 7px 1px rgba(0, 0, 0, 0.3);
        }

        .Button_isHollow {
            width: 98px;
            height: 38px !important; // override vendor hover height
            text-transform: none;
            @include mobile {
                margin-left: 15px;
                margin-right: 15px;
            }
        }
    }

    &-SubCategoriesWrapper {
        margin: 0 auto;
        width: auto;
        max-width: 1400px;
        min-width: 700px;

        .Menu-ItemCaption {
            font-weight: 400;
        }
    }
}
