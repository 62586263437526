.how-it-is-done{

    p:last-child {
        border-bottom: 1px solid $color-gray9;
        padding-bottom: 20px;
    }

    .divider {
        padding: 25px 0 10px 0;
        border-top: 1px solid $color-gray9;
    }
}

