/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.BlogPostsListItem {
    width: 33.333%;
    padding: 0 10px;
    float: left;
    margin-top: 30px;

    @include tablet {
        width: 50%;
    }

    @include mobile {
        width: 100%;
        float: none;
    }

    &-Image {
        img {
            position: relative;
        }
    }

    &-PublishTime {
        margin: 6px 0;
        font-size: 16px;
    }

    &-Title {
        a {
            font-size: 24px;
        }
    }

    &-ShortContent {
        margin: 6px 0;
        font-size: 14px;
    }
}