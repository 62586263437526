.ContactPage {
    &-Wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &-Header {
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        color: $black;
        padding: 0;
        margin: 7px 0 22px;
    }

    &-Details {
        display: flex;
        width: 100%;
        gap: 20px;

        @include mobile {
            flex-direction: column;
            gap: 0px;
        }
    }

    &-Card {
        width: calc(50% - 10px);
        display: flex;
        flex-direction: column;

        p, .Tel, .Email {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: $black;
            margin: 0 0 4px;
            padding: 0;

            .Strong {
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                color: $black;
                margin-left: 5px;
            }

            strong {
                margin-left: 5px;
                color: $black;
            }
        }

        @include mobile {
            width: 100%;
            margin-bottom: 40px;

            p, .Tel, .Email {
                font-size: 16px;
                line-height: 22px;
    
                .Strong {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }

    .Tel, .Email {
        display: inline-flex;
        align-items: center;
        &::before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
    }

    .Tel {
        color: $color-gray6;

        &::before {
            background-image: url('../../icons/phone-call.svg');
        }
    }

    .Email {
        &::before {
            background-image: url('../../icons/mail.svg');
        }
    }

    .Button-Primary {
        text-decoration: none;
        padding: 13px 10px;
        margin: 16px 0;
        background-color: $color-blue2;
        color: $color-gray2;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        width: 319px;
        transition: 0.25s background-color ease-out;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
            width: 100%;
            max-width: 360px;
        }

        &:hover {
            background-color: $color-blue4;
        }

        &_download {
            &::after {
                content: '';
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-left: 24px;
                background-image: url('../../icons/download_2.svg');
                background-repeat: no-repeat;
            }
        }
    }

    &-Map {
        margin: 60px 0 90px;
        width: 100%;
        min-height: 500px;

        iframe {
            width: 100%;
            height: auto;
            min-height: 500px;
        }

        @include mobile {
            margin: 30px 0 50px;
        }
    }

    &-Bottom {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 60px;

        .ContactPage-Card {
            width: 100%;
            margin-bottom: 70px;    
        }

        @include mobile {
            margin-top: 30px;
        }
    }

    &-Grid {
        display: grid;
        grid-column-gap: 8px;
        grid-template-columns: 70px 1fr;

        span, p {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: $black;
        }

        p {
            font-weight: 600;
            line-height: 24px;
        }

        > div {
            width: 100%;

            p {
                font-weight: 400;

                &:first-child {
                    font-weight: 600;
                }
            }

            @include desktop {
                width: 60%;
            }
        }

        @include mobile {
            span, p {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    &-Logos {
        display: flex;
        width: 100%;
    }

    &-BoxCompanies {
        display: flex;
        padding-left: 20px;
        justify-content: space-between;
        padding-right: 20px;
        padding-bottom: 90px;
        flex-direction: row;
        @include mobile {
            flex-direction: column;
            align-items: center;
            padding-bottom: 0px;
            margin-bottom: 60px;
            img {
                margin-bottom: 20px;
            }
        }

        img {
            width: auto;
        }
    }

    .Form {
        .Button {
            
            @include mobile {
                width: 100%;
            }
        }
    }
}

.CmsPage {
    .ContactPage {
        h3 {
            margin: 0 0 26px;
            padding: 0 0 8px;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            width: 100%;
            color: $black;
            border-bottom: 1px solid $color-gray4;
        }
    }
}