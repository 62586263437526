/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../style/abstract/variables";
@import "../../../style/abstract/mixins";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.component-tooltip {
    display: inline-block;
    position: static;

    .component-tooltip__element {
        display: inline-block;
    }

    .component-tooltip__tooltip {
        position: fixed;
        z-index: 20000;
        background: #000;
        color: #fff;
        border-radius: 5px;
        display: none;
        font-style: italic;
        font-weight: 400;
        font-size: 14px !important;
        line-height: 18px;
        text-align: left;
        max-width: 405px;

        &.component-tooltip__tooltip--is-visible {
            display: block;
        }

        * {
            font-style: normal;
        }
    }

    &.component-tooltip--size-md {
        .component-tooltip__tooltip {
            padding: 0.5em 0.8em;
            font-size: 1em;
        }
    }

    &:not(.component-tooltip--outline) {
        &.component-tooltip--color-default {
            .component-tooltip__tooltip {
                background: #fff;
                color: #111;
                border: 1px solid #000;
            }
        }
    }

    &.component-tooltip--outline {
        &.component-tooltip--color-default {
            .component-tooltip__tooltip {
                background: #fff;
                border: 1px solid #000;
                color: #000;
            }
        }
    }
}
