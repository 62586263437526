.CmsPage {
    &-Content {
        .left-image-block {
            padding: 30px 30px;

            img {
                float: left;
                width: 40%;

                @include mobile {
                    width: 100%;
                    padding: 0;
                }
            }

            .txt-content {
                padding-left: 10%;
                float: right;
                width: 60%;
                font-size: 14px;
                line-height: 22px;
                text-align: justify;
                
                @include mobile {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }

    ul {
        padding-left: 20px;

        li {
            list-style-type: disc;
            list-style-position: outside;
            margin-bottom: 6px;

            &::before {
                content: "";
            }

            &:last-child {
                margin-bottom: 20px;
            }
        }
    }
}
