/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

$listMargin: 5px;

.AttachmentGallery {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    &-DropZone {
        width: 100%;
        min-height: 200px;
        border: 2px dashed $color-gray7;
        border-radius: 2px;
        margin-bottom: 20px;
        padding: 35px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background: $color-gray3;
        transition: border-color 0.2s;
        @include mobile {
            justify-content: center;
            min-height: 150px;
            h3, p {
                display: none;
            }
        }
        p {
            &.Separator {
                margin-bottom: 0;
            }
        }
        .Button {
            margin: 0;
            width: auto;
        }
        &.dragging {
            justify-content: center;
            border-color: $color-green1;
            * {
                pointer-events: none;
            }
            h4 {
                font-size: 34px;
            }
        }
    }
    
    &-FileInput {
        display: none;
    }
    
    &-FileList {
        width: 100%;
        border: $listMargin solid white;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    
    &-FileItem {
        min-width: 250px;
        height: 40px;
        margin: $listMargin;
        padding: 8px;
        position: relative;
        flex: 1 1 250px;
        display: flex;
        align-items: center;
        background: $color-gray3;
        border-radius: 4px;
        
        .Name {
            width: 100%;
            margin-left: 5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: $black;
        }
        .Size {
            white-space: nowrap;
            margin-right: 24px;
            margin-left: 5px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $color-gray6;
        }
        .FileIcon {
            flex: 0 0 24px;
        }
    }
    &-CloseBtn {
        width: 24px;
        height: 24px;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 5px;
        cursor: pointer;
    }
    .AttachmentGallery-DropZone {
        h3, h4 {
            border: none;
            margin: 0;
            color: $color-gray7;
        }
    }
}
