/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

$green: #b6d97d;

.OfferSummary {
    display: flex;
    font-size: $font-size-p1;
    grid-area: summary;

    .card {
        margin-bottom: 0;
        width: 100%;
    }

    .card__header {
        margin-bottom: 0;
    }

    &_chip {
        background-color: $green;
        border-radius: 50px;
        font-size: $font-size-p1;
        padding: 4px 10px;
    }

    .Button {
        background-color: $white;
        margin-top: 20px;
        width: 100%;

        svg {
            margin-right: 6px;
        }

        path {
            stroke: $color-primary;
        }
    }

    &-Currency {
        font-size: $font-size-p2;
        font-weight: $font-weight-bold;
        position: absolute;
        right: 13px;
        top: 50%;
        transform: translateY(-50%);
    }

    &-Row {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding: 12px 0 0 0;

        span:nth-child(2) {
            font-weight: $font-weight-bold;
        }

        span:nth-child(2):not(.OfferSummary_small) {
            font-size: $font-size-h6;
        }

        &_divider {
            border-bottom: 1px $white solid;
            padding-bottom: 12px;
        }
    }

    &-Shipping {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-top: 12px;

        &Value {
            font-weight: $font-weight-bold;
            padding: 13px 30px 13px 10px;
            width: 100%;
        }
    }

    &-ShippingWrapper {
        position: relative;
        width: 105px;
    }

    &-VATDisclaimer {
        color: $color-gray6;
        font-size: $font-size-p3;
        margin-top: 12px;
    }

    &_small {
        font-size: $font-size-p1;
    }
}