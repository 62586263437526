/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.Breadcrumbs {
    &-List {
        border-bottom: 1px #e0e0e0 solid;
        padding: 0 !important;

        @include mobile {
            display: none;
        }

        @include tablet {
            border: none;
        }

        &_noBorder {
            border-bottom: none;
        }
    }

    &-Bigger {
        @include desktop {
            margin-top: 0;
        }

        @include tablet {
            margin-top: 200px;
        }
    }
}