@mixin flex(
    $wrap: nowrap,
    $dir: row,
    $align: flex-start,
    $justify: flex-start
) {
    display: flex;
    flex-wrap: $wrap;
    flex-direction: $dir;
    align-items: $align;
    justify-content: $justify;
}

@mixin ellipsis($width: 100%) {
    width: $width;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin size($width: 20px, $height: $width) {
    width: $width;
    height: $height;
}

@mixin media($min: false, $max: false) {
    @if $min and $max {
        @media screen and (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $min {
        @media screen and (min-width: $min) {
            @content;
        }
    } @else if $max {
        @media screen and (max-width: $max) {
            @content;
        }
    }
}
