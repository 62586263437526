/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.BoxFillerList {
    grid-area: address;

    &-Item {
        border: 1px $color-gray4 solid;
        border-radius: 2px;
        height: 100%;
        padding: 12px;
        cursor: pointer;
        &::before {
            content: '';
        }
        &_isSelected {
            background-color: $color-gray3;
            border: 1px $color-primary solid;
            .BoxFillerList-CheckmarkIcon {
                background-color: $color-primary;
                display: block;
                height: 22px;
                padding: 3px;
                position: absolute;
                right: 0;
                top: 0;
                width: 22px;
            }
        }

        p {
            padding: 0;
            margin: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $color-gray6;

            @include mobile {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &-Items {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
        grid-template-rows: minmax(144px, 1fr);
        width: 100%;
        
        @include tablet {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }
        
        @include mobile {
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        }
    }

    &-Label {
        color: $color-gray6;
        display: inline-block;
        font-size: $font-size-p2;
        font-weight: $font-weight-bold;
        margin-bottom: 6px;
    }

    &-SelectFiller {
        align-items: center;
        display: flex;
        font-size: $font-size-p1;
        gap: 6px;
        cursor: pointer;
        color: var(--link-color);
        font-weight: 700;
        
        &:hover {
            & .BoxFillerList-AddPlusIcon {
                circle {
                    fill: var(--link-hover);
                }
            }
        }
    }

    &-Actions {
        display: flex;
        justify-content: space-between;
    }

    &-ShowHideFillers {
        align-items: center;
        display: none;
        font-size: $font-size-p1;
        gap: 6px;
        cursor: pointer;
        color: var(--link-color);
        font-weight: 700;

        span {
            align-items: center;
            background: $color-primary;
            border-radius: 2px;
            display: inline-flex;
            height: 24px;
            justify-content: center;
            transition: background 0.15s ease-in;
            width: 24px;

            svg {
                transition: all 0.15s ease-in;
            }

            &_open svg {
                transform: rotate(-180deg);
            }
        }
    }

    &-ProductName {
        min-height: 50px;
    }

    &-ProductSku {
        align-items: center;
        display: flex;
        font-weight: 300;
        margin: 0;
    }

    &-ProductColor {
        border: 1px solid var(--product-card-color-border-color);
        border-radius: 50%;
        display: block;
        height: 12px;
        margin-bottom: 3px;
        margin-left: 10px;
        width: 12px;
    }

    &-NotAssembledMessage {
        font-size: $font-size-p1;
        color: var(--link-color);
        font-weight: 700;
        padding-top: 15px;
    }

    .FillersList {
        .card__body {
            display: none;
            margin-top: 12px;
        }

        .card__header {
            margin-bottom: 0;
            
            &--divider {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }

    &_open {
        .FillersList {
            .card__body {
                display: block;
            }
        }
    }

    &_selected {
        .BoxFillerList-ShowHideFillers {
            display: flex;
        }
        .BoxFillerList-SelectFiller {
            display: none;
        }
    }
}

.BoxFillerList_selected.BoxFillerList_open {
    .BoxFillerList-ShowHideFillers {
        svg {
            transform: rotate(-180deg);
        }
    }
}
