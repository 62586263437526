/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.FieldNumber {
    &-Input {
        border: 1px solid $color-gray4;
        margin-right: 4px;
        @include size(80px, 38px);
    }

    &-ButtonsWrapper {
        order: 2;
        height: 38px;
        @include flex(null, column, center, space-between);
    }

    &-Button {
        border: 1px solid $color-gray4;
        @include size(17px);
        @include flex(null, null, center, center);

        &_subtract {
            transform: rotate(180deg);
        }

        &:disabled {
            opacity: 0.6;
        }
    }
}
