/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.BlogCategoriesMain {
    &-List {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        div {
            a {
                padding: 10px 25px;
                display: inline-block;
                font-size: 16px;
                text-transform: uppercase;
            }
        }

        @include mobile {
            display: block;
        }
    }

    &-Wrapper {
        height: 100%;
        position: relative;
    }
}