/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.OfferAddressList {
    grid-area: address;

    &-CheckmarkIcon {
        display: none;
    }

    &-Item {
        border: 1px $color-gray4 solid;
        border-radius: 2px;
        height: 100%;
        padding: 12px;
        cursor: pointer;
        &::before {
            content: '';
        }
        &_isSelected {
            background-color: $color-gray3;
            border: 1px $color-primary solid;
            .OfferAddressList-CheckmarkIcon {
                background-color: $color-primary;
                display: block;
                height: 22px;
                padding: 3px;
                position: absolute;
                right: 0;
                top: 0;
                width: 22px;
            }
        }

        p {
            padding: 0;
            margin: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $color-gray6;

            @include mobile {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &-Items {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
        grid-template-rows: minmax(144px, 1fr);
        width: 100%;
        
        @include tablet {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        }
        
        @include mobile {
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        }
    }

    &-Label {
        color: $color-gray6;
        display: inline-block;
        font-size: $font-size-p2;
        font-weight: $font-weight-bold;
        margin-bottom: 6px;
    }

    &-NewAddress {
        align-items: center;
        display: flex;
        font-size: $font-size-p1;
        gap: 6px;
        cursor: pointer;
        color: var(--link-color);
        font-weight: 700;
        
        &:hover {
            & .OfferAddressList-AddPlusIcon {
                circle {
                    fill: var(--link-hover);
                }
            }
        }
    }

    &-Pagination {
        margin-top: 20px;
    }

    &-SearchField {
        margin-bottom: 20px;

        .Field {
            margin-top: 0;

            input {
                border-radius: 4px;
                box-shadow: 0px 1px 6px rgba(0, 51, 143, 0.1);
                width: 100%;
            }
        }
    }
}
