/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../style/abstract/variables";
@import "../../../style/abstract/mixins";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.table {
    width: 100%;
    position: relative;
    .table__tbody__td--align-right, .table__thead__th--align-right  {
        @include desktop {
            justify-content: flex-end;
            padding-right: 20px;
        }

        @include tablet {
            justify-content: flex-end;
            padding-right: 10px;
        }

        @include mobile {
            justify-content: flex-end;
            padding-right: 10px;

        }
    }

    .table__tbody__td--padding-left, .table__thead__th--padding-left {
        @include desktop {
            padding-left: 40px;
        }
        @include tablet {
            padding: 0 30px;
        }
        @include mobile {
            padding: 0 30px;
        }
    }

    .table__inner {
        @include mobile {
            overflow: scroll;
        }

        .table__thead {
            padding: 12px 15px;
            background: #3f3f3f;
            box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            margin-bottom: 10px;

            .table__thead__th {
                display: flex;
                align-content: center;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-transform: uppercase;
                color: #f9f9f9;

                & > div {
                    display: block;
                    width: auto;
                    height: auto;
                    min-height: auto;
                }
            }
        }
        .table__tbody {
            .table__tr {
                margin-bottom: 10px;
                background: #f8f8f8;
                border-radius: 2px;
                min-height: 78px;
                padding: 0 15px;

                .table__tbody__td {
                    display: flex;
                    align-items: center;

                    & > div {
                        width: auto;
                        height: auto;
                        min-height: auto;
                        display: inline-block;
                        font-size: 16px;
                        line-height: 22px;
                    }

                    &--disabled {
                        opacity: 0.6;
                    }
                }
            }
        }

        &.table--color-white {
            .table__thead {
                background: #fff;
                box-shadow: none;
                border-bottom: 1px solid #e0e0e0;
                padding: 0;

                .table__thead__th {
                    color: rgb(31, 31, 31);
                    font-weight: 400;
                    font-size: 16px;
                    text-transform: none;
                    padding-left: 29px;
                    padding-top: 9px;
                    padding-bottom: 9px;

                    & > div {
                        text-align: left;
                        margin: 0;
                    }

                    &--disabled {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}
