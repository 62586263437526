/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Logo {
    --placeholder-image: none;
    --placeholder-animation: none;
    --image-default-background: transparentf;
    height: 100%;
    max-height: 20px;

    > img {
        height: 100%;
        width: auto;
        @include desktop {
            @media (max-width: 1000px) {
                height: 80%;
            }
        }
    }

    &-Placeholder {
        position: absolute;
        inset-inline-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-block-start: 0;
        margin: auto;

        .Image {
            height: var(--header-logo-height);
        }
    }

    &-Accent {
        fill: var(--primary-base-color);
    }
}
