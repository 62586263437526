/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ExpandableContentShowMore {
    text-align: start;
    margin: 0 8px;

    &-ShowAllButton.Button_likeLink {
        --button-height: fit-content;
        --button-hover-height: fit-content;
        --button-hover-padding: 0;
        color: $color-blue2;
        margin-block-start: 8px;
        font-weight: 700;

        &:focus:not(:hover) {
            text-decoration: unset;
        }
    }

    &-ExpandableChildren {
        transition: .5s;
        overflow: hidden;
    }
}
