/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #000;
}

.SearchField {
    display: none;
    margin: 8px auto;
    max-width: 100%;
    width: 100%;

    @include desktop {
        display: block;
        flex: 1;
        max-width: 490px;
    }

    &_isVisible {
        display: block;

        @include mobile {
            margin-block-start: 16px;
        }
    }

    &_isActive {
        .SearchOverlay {
            &-Results {
                display: block;
            }
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        position: absolute;
        height: 24px;
        inset-inline-end: 15px;
        inset-block-start: calc(50% - 12px);
        width: 24px;
        z-index: 3;
    }

    &-Input {
        @include desktop {
            @media (max-width: 940px) {
                font-size: 14px;
            }
        }
        font-family: $font-primary;
        font-size: 16px;
        font-style: italic;
        width: 100%;
        border-radius: 25px;
        max-height: 38px;
        z-index: 2;
    }
}
