/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CmsPage {
    .CmsPage-Wrapper {
        margin-top: 0;
    }

    &_isBreadcrumbsHidden {
        margin-block-start: calc(var(--header-total-height) + 12px);

        @include mobile {
            margin-block-start: calc(var(--header-total-height) + 14px);
        }
    }

    &-Content {
        .cms-content {
            @include default-list;
        }

        h2 {
            @include mobile {
                text-align: center;
            }
        }
    }

    &-Wrapper {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 32px;
        margin-block-start: calc(var(--header-total-height) + 12px);
        margin-block-end: var(--header-nav-height);
        margin-inline: auto;

        @include mobile {
            padding-inline: 13px;
            margin-block-start: calc(var(--header-total-height) + 14px);
        }

        @include desktop {
            margin-block-start: 0;
        }

        &_page_width {
            &_full {
                max-width: 100%;
                padding-inline: 0;
            }
        }
    }
    &-Heading {
        padding-top: 24px;
        margin-block-end: 18px;

        @include mobile {
            margin-block-end: 21px;
            display: none;
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0;
        margin-block-end: 10px;
        padding-block-end: 30%;

        @include mobile {
            padding-block-end: 60%;
        }

        &:first-child {
            padding-block-end: 40%;

            @include mobile {
                padding-block-end: 100%;
            }
        }
    }

    .TextPlaceholder {
        margin-block-end: 22px;
        display: block;

        @include mobile {
            margin-block-end: 25px;
        }
    }

    .Slider-Crumbs {
        position: unset;
        @include mobile {
            margin-block: 20px 28px;
        }
    }

    h3 {
        @include mobile {
            font-size: 14px;
            text-transform: none;
        }
    }

    h2 {
        @include mobile {
            text-transform: none;
        }
    }

    h1 {
        @include mobile {
            text-transform: none;
        }
    }

    .cmsRoot {

        @include mobile {
            padding-block-start: 20px;
        }

        p {
            margin-bottom: 10px;
            font-size: 14px;
            color: black;
            font-weight: 400;
            line-height: 25px;
            text-align: justify;
        }

        a {
            cursor: pointer;
        }

        button {
            cursor: pointer;
            transition: opacity 0.5s;
            &:hover {
                opacity: 0.9;
            }
        }

        h1 {
            font-size: 26px;
        }

        h2 {
            font-size: 24px;
        }

        h3 {
            font-size: 18px;
        }

        h4 {
            font-size: 16px;
        }

        ul {
            margin-bottom: 10px;
            padding-inline-start: 40px;
            list-style-type: disc;
            li {
                margin: 0;
                list-style-type: disc;
                list-style-position: outside;
            }
        }
        table {
            td {
                padding: 0;
            }
        }
        ol {
            padding-inline-start: 40px;
            li {
                list-style-type: decimal;
                &::before {
                    content: '';
                }
            }
            &.lower-alpha {
                li {
                    list-style-type: lower-alpha;
                }
            }
        }

        table {
            margin: auto;
        }

        img {
            width: 100%;
            max-width: 100%;
        }
        .fs16 {
            font-size: 16px;
        }
        .fs18 {
            font-size: 18px;
            &--all {
                * {
                    font-size: 18px;
                }
            }
        }
        .fs24 {
            font-size: 24px;
        }
        .fs26 {
            font-size: 26px;
        }
        .text-center {
            text-align: center;
            &--allChild {
                * {
                    text-align: center;
                }
            }
        }
        .contact-cell {
            min-height: 250px;
            display: flex;
            border-bottom: 1px #d1d1d1 solid;
            padding: 10px 0;
            h1 {
                font-size: 24px;
                font-weight: 300;
            }
            &--left {
                float: left;
                margin-right: 30px;
                width: 86px;
                height: 86px;
                overflow: hidden;
                border-radius: 50px;
                margin-top: 20px;
            }
            &--right {
                float: left;
                width: calc(100% - 116px);
            }
            &--image {
                height: 86px;
                width: 86px;
                background-size: cover;
            }
            &::after {
                content: '';
                display: table;
                clear: both;
            }
            &:last-child {
                border-bottom: none;
            }
        }
        .image-inline-list {
            display: flex;
            flex-direction: column;
            width: 100%;
            article {
                width: 100%;
                display: flex;
                border: none;
                div {
                    width: 100%;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                }
                .image-inline-list__image {
                    width: 200px;
                    justify-content: center;
                    img {
                        height: auto;
                        max-width: 80%;
                    }
                }
            }

        }
        .cms-btn {
            height: 40px;
            width: 160px;
            display: flex;
            button {
                height: 100%;
                width: 100%;
                background: #009fe3;
                border-radius: 2px;
                color: white;
                font-size: 12px;
                text-align: center;
                text-transform: uppercase;
            }
            &--big {
                height: 75px;
                width: 300px;
                button {
                    font-size: 20px;
                }
            }
            &--download {
                height: 30px;
                width: 250px;
                position: relative;
                button {
                    font-size: 12px;
                    text-align: left;
                    text-transform: none;
                    padding-left: 10px;
                }
                i {
                    width: 28px;
                    height: 28px;
                    border: 1px solid black;
                    border-radius: 2px;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;
                    right: -32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg {
                        width: 18px;
                        height: 18px;
                        fill: black;
                    }
                }
            }
        }
        .line {
            width: 20px;
            height: 1px;
            background: #999999;
            position: relative;
        }
        .no-margin {
            margin: 0;
        }
        .flex {
            display: flex;
            &--centered {
                justify-content: center;
                align-items: center;
            }
        }
    }
}
