/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

$defaultTransition: 0.15s ease-in;

@mixin mobile-box {
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.OfferMarkUp {
    grid-area: markUp;
    margin-top: 20px;

    &-Box {
        background: $white;
        border-radius: 4px;
        box-shadow: 0px 1px 6px 0px #00338F1A;
        display: flex;
        font-size: $font-size-p2;
        padding: 20px;

        @include tablet {
            @include mobile-box;
        }

        @include mobile {
            @include mobile-box;
        }
    }

    &-InputWrapper {
        min-width: 75px;
        position: relative;
    }

    &-Label {
        font-size: $font-size-p1;
        font-weight: $font-weight-bold;
        position: absolute;
        right: 13px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }

    &-PercentInput {
        border-radius: 2px;
        font-size: $font-size-p1;
        font-weight: $font-weight-bold;
        grid-area: input;
        height: 48px;
        max-width: 75px;
        outline: none;
        padding: 11px 30px 11px 14px;
        pointer-events: all;
        transition: border $defaultTransition;
        text-align: left;

        &:focus {
            border: 1px solid $color-primary;
        }
    }

    &-PriceOption {
        color: $color-gray10;
        font-size: $font-size-p1;
        font-weight: 700;
        text-transform: uppercase;
        transition: color $defaultTransition;

        &_priceSwitch,
        &_isCustomPrice {
            color: $black;
        }


    }

    &-Recalculate {
        column-gap: 6px;
        display: grid;
        grid-template-areas: "label label"
            "input button";
        row-gap: 10px;
    }

    &-RecalculateLabel {
        font-weight: 400;
        grid-area: label;
    }

    &-SwitchContainer {
        align-items: center;
        display: flex;
        gap: 12px;
        justify-content: center;
        width: 100%;

        @media (max-width: 580px) {
            flex-direction: column;
        }
    }
}