/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.CurrencySwitcher {
    --selector-width: 56px;

    border-block-end: 1px solid var(--primary-divider-color);

    @include mobile {
        margin: 87px 16px 0 16px;
    }

    .Field {
        margin-block-start: 0;

        &Select {
            @include desktop {
                width: var(--selector-width);
                padding-inline-start: 12px;
            }

            .ChevronIcon {
                inset-inline-end: 0;
                fill: $white;

                @include mobile {
                    fill: $black;
                    margin-right: 6px;
                }
            }

            &-Select {
                border: none;
                font-size: $font-size-p2;
                font-weight: $font-weight-light;
                margin: 0;
                opacity: 1;
                padding-inline-start: 0;
                line-height: 23px;
            }

            &::after {
                height: 13px;
                inset-inline-end: 0;
                width: 13px;
            }
        }
    }

    @include desktop {
        border-block-end: none;

        .FieldSelect {
            &-Select {
                border: none;
                font-size: $font-size-p3;
                font-weight: 600;
                padding: 0;
            }

            &-Options {
                overflow-y: auto;
                width: min-content;
                min-width: var(--selector-width);

                &_isExpanded {
                    border-block-start: 1px solid var(--primary-divider-color);
                }
            }

            &-Option {
                line-height: 28px;
                padding: 6px 12px;
                font-size: $font-size-p3;
            }
        }
    }

    &-SignedIn {
        padding: var(--input-padding);
        padding-left: 0;
        font-size: $font-size-p2;
        width: 100%;
    }
}
