/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.StoreItem {
    &-LangPL {
        background: url("../../style/icons/flags/PL.jpg") no-repeat;
        width: 24px;
        height: 14px;
    }

    &-LangDE {
        background: url("../../style/icons/flags/DE.png") no-repeat;
        width: 24px;
        height: 14px;
    }

    &-LangFR {
        background: url("../../style/icons/flags/FR.png") no-repeat;
        width: 24px;
        height: 14px;
    }

    &-LangCZ {
        background: url("../../style/icons/flags/CZ.png") no-repeat;
        width: 24px;
        height: 14px;
    }

    &-LangEN {
        background: url("../../style/icons/flags/EN.png") no-repeat;
        width: 24px;
        height: 14px;
    }

    &-Item {
        font-size: 12px;
        padding: 10px 12px;
        white-space: nowrap;
        width: 100%;
        display: flex;

        span {
            margin-left: 5px;
        }

        @include mobile {
            font-size: 14px;
        }

        &:hover {
            background: var(--secondary-base-color);
        }
    }
}
