.CmsLandingPage {
    .content-wrapper {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        margin-inline: auto;
        padding: 0 30px;
    }

    .content-box {
        padding: 50px 30px;

        @include mobile {
            padding: 30px 10px;
        }

        @include tablet {
            padding: 10px 30px;
        }
    }

    .content-center {
        text-align: center;

        .Button-Primary {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .content-center-all {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .columns {
        display: flex;

        @include mobile {
            display: block;
        }
    }
    .column {
        @include mobile {
            width: 100% !important;
        }
    }
    .column_1_2 { width: 50%; }
    .column_1_3 { width: 33%; }
    .column_2_3 { width: 66%; }
    .column_1_4 { width: 25%; }
    .column_3_4 { width: 75%; }
    .column_1_5 { width: 20%; }
    .column_2_5 { width: 40%; }
    .column_3_5 { width: 60%; }
    .column_4_5 { width: 80%; }

    p { font-size: 16px; line-height: 27px; }
    p.content-large { font-size: 22px; line-height: 37px; }
    .title-large {
        font-size: 64px;
        line-height: 110px;

        @include mobile {
            font-size: 56px;
            line-height: 80px;
        }
    }
    .title-big { font-size: 56px; line-height: 76px; }
    .title-medium { font-size: 36px; line-height: 61px; }
    .title-small { font-size: 28px; line-height: 48px; }

    ul {
        padding-left: 20px;

        li {
            font-size: 16px;
            line-height: 27px;
            list-style-type: disc;
            list-style-position: outside;

            &::before {
                content: "";
            }
        }
    }

    .gallery {
        padding: 10px 8% 30px 8%;
        margin-top: 35px;

        img {
            display: inline-block;
            margin-left: 2%;
            margin-top:10px;

            width: calc((100% - 10%) / 3);

            @include mobile {
                width: 50%;
                height: auto;
                padding: 15px;
                margin: 0;
            }

            @include tablet {
                width: calc((100% - 10%) / 2);
            }
        }

        @include mobile {
            padding: 10px 0;
        }
    }

    .Button-Primary {
        text-decoration: none;
        padding: 13px 10px;
        margin: 30px 0;
        background-color: $color-blue2;
        color: $color-gray2;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        width: 219px;
        transition: 0.25s background-color ease-out;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
            width: 100%;
            max-width: 360px;
        }

        &:hover {
            background-color: $color-blue4;
        }
    }

    .customBgLinear {
        background: linear-gradient(rgb(0, 25, 68) 0%, rgb(0, 45, 120) 100%) border-box;
        p {
            color: #ffffff;
        }
    }

    .customBgGrey {
        background-color: rgb(229, 229, 229);
    }

    .customFeatureListItem {
        padding: 15px 0;
    }

    .customCircle {
        border-radius: 355px;
        overflow: hidden;
        width: auto;
    }

    .customImageGrid {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: [col1-start] 50% [col2-start] 50% [col2-end];
        grid-template-rows: [row1-start] auto [row2-start] auto [row2-end];

        .firstColumn {
            grid-column: col1-start;
            grid-row: row1-start / row2-end;
        }
        .secondColumnFirstRow {
            grid-column: col2-start;
            grid-row: row1-start;
        }
        .secondColumnSecondRow {
            grid-column: col2-start / col2-end;
            grid-row: row2-start / row2-end;
        }
    }

    .customMovie {
        float: none;
        clear: both;
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 25px;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .customRoundImageBox {
        width: 290px;
        height: 290px;
        margin: 0 auto;
    }

    .swiper-wrapper {
        @include desktop {
            justify-content: center;
        }
    }
}
