/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../style/abstract/variables";
@import "../../../style/abstract/mixins";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.typography {
    &.typography--color-success {
        color: $color-green3;
    }

    &.typography--color-danger {
        color: $color-red1;
    }
    
    &.typography--color-warning {
        color: $color-orange2;
    }
    
    &:last-child {
        margin-bottom: 0;
    }

    h3 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #5b5b5b;
        margin: 0;
    }

    h2 {
        font-weight: 500;
        font-size: 18px;
        color: #1f1f1f;
        margin: 0;
    }
}
