/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../style/abstract/variables";
@import "../../../style/abstract/mixins";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.label {
    margin-right: 5px;
    text-transform: uppercase;
    color: $white;
    padding: 4px;
    font-size: $font-size-p2;
    background-color: $color-gray9;
    font-weight: 600;
    border-radius: $label-border-radius;
    line-height: 18px;
    display: inline-block;

    &.label--color-danger {
        background-color: $color-red1;
    }

    &.label--color-primary-darken {
        background-color: $color-blue4;
    }

    &.label--color-purple {
        background-color: #ae3c83;
        color: white;
    }

    &.label--color-violet {
        background-color: #941e82;
        color: white;
    }

    &.label--color-warning {
        background-color: #de8b22;
        color: white;
    }

    &.label--color-orange {
        background-color: #f18e00;
        color: white;
    }

    &.label--color-lightblue {
        background-color: #009fe3;
        color: white;
    }

    &.label--color-blue {
        background-color: #019fe3;
        color: white;
    }

    &.label--size-md {
        font-size: 14px;
        line-height: 16px;
        padding: 3px 10px;

        @include mobile {
            margin-bottom: 15px;
        }
    }

    &.label--size-lg {
        font-size: 20px;
        line-height: 22px;
        padding: 10px;
    }

    &.label--squared {
        border-radius: 4px;
    }
}
