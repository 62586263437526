/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.consultant-card {
    width: 100%;
    height: 190px;

    @include mobile {
        height: 220px;
    }

    h3 {
        margin-block-end: 6px;
    }

    p {
        font-family: $font-primary;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 6px;
    }

    &.card {
        .card__background {
            left: 118px;
            bottom: -20px;
        }
    }
}
