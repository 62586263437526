/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.MyAccountDashboard {
    &-RecentlyOrders {
        .RecentlyOrders-Button {
            text-transform: uppercase;
        }
        .orders-table {
            .table__tbody {
                .table__tr {
                    .table__tbody__td {
                        &:first-child {
                            > div {
                                display: flex;
                                width: 100%;
                                justify-content: space-between;
                                align-items: center;
                            }
                        }
                    }
                }
            }
            .table__thead {
                .table__tr {
                    align-items: center;
                }
            }
        }
    }
.RecentlyOrders {
    &-TopSection {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &-Legend {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: $color-gray6;
        margin-top: 12px;

        svg {
            margin-right: 8px;
        }
    }
}
}
