/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../style/abstract/variables";
@import "../../../style/abstract/mixins";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.card {
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(0, 51, 143, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;

    .card__body {
        position: static;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        &--divider {
            padding-bottom: 12px;
            border-bottom: 1px solid #e0e0e0;
        }
    }

    &.card--color-purple {
        background: #c8519b;
        color: #fff;

        .card__header {
            h2 {
                color: #fff;
            }
        }
    }

    &.card--color-info {
        background: #009fe3;
        color: #fff;

        .card__header {
            h2 {
                color: #fff;
            }
        }
    }

    &.card--color-blue {
        background: #396eb4;
        //background: #019fe3;
        color: $white;

        .card__header {
            h2 {
                color: $white;
            }
        }
    }


    &.card--color-light-blue {
        background: #cfe7f8;
        border: 1px $black solid;
        color: $black;
        .card__header--divider {
            border-bottom: 1px $white solid;
        }
        .card__header {
            h2 {
                color: $black;
            }
        }
    }

    &.card--color-violet {
        background: #6d52bd;
        color: #fff;

        .card__header {
            h2 {
                color: #fff;
            }
        }
    }

    &.card--color-warning {
        background: #fca639;
        color: #fff;

        .card__header {
            h2 {
                color: #fff;
            }
        }
    }

    &.card--color-danger {
        background: #ed4242;
        color: #fff;

        .card__header {
            h4 {
                color: #fff;
            }
        }

        .typography * {
            color: #fff;
        }
    }

    &.card--color-primary {
        background: #009fe3;
        color: #fff;

        .card__header {
            h2 {
                color: #fff;
            }
        }
    }

    &.card--color-gray {
        background: #f8f8f8;
        color: #5b5b5b;

        .card__header {
            h2,
            h4 {
                color: #5b5b5b;
            }
        }
    }

    &.card--color-gray2 {
        background: #e5f5fc;
    }

    &.card--color-lightblue {
        background: #f1f3fb;
        color: #5b5b5b;

        .card__header {
            h2,
            h4 {
                color: #5b5b5b;
            }
        }
    }

    &.card--color-saldoplus, 
    &.card--color-saldominus,
    &.card--color-creditlimit,
    &.card--color-creditlimitinactive {
        background: #2bca7d;
        color: #fff;

        .card__header {
            h2 {
                color: #fff;
            }
        }

        h3, h4 {
            color: #fff;
            margin-top: 0;
            margin-bottom: 0px;
            font-size: 14px;
        }

        p {
            color: #fff;
            margin: 0;

            &.saldocard-value {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 5px;
            }
        }
    }

    &.card--color-saldominus {
        background: #ff5038;
    }

    &.card--color-creditlimit {
        background: #0097b2;
    }

    &.card--color-creditlimitinactive {
        background: #d9d9d9;
    }

    .card__header {
        display: flex;
        margin-bottom: 20px;

        @include mobile {
            align-items: baseline;
        }

        & > * {
            display: block;
        }

        h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #1f1f1f;
            position: relative;

            @include mobile {
                font-size: 16px;
            }
        }

        h2 {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #5b5b5b;
            margin: 0;
        }
    }

    .card__background {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        img {
            width: 100%;
            height: 100%;
        }
    }
}
