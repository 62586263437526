/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

$green: #b6d97d;
$minWidthSection: 162px;

@mixin divider {
    &::after {
        background: $color-gray4;
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        right: -20px;
        width: 1px;
    }
}

@mixin hideDivider {
    &::after {
        display: none;
    }
}

.hideOnOfferScroll {
    .OfferHeader {
        transform: translateY(var(--translate));
    }
}

.OfferHeader {
    background-color: $white;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
    grid-area: header;
    height: 80px;
    position: fixed;
    top: var(--top);
    transition: all 0.3s;
    width: 100%;
    z-index: 100;

    @include mobile {
        background-color: $white;
        height: auto;
        transform: translateY(var(--translate));
        padding: 20px;
        z-index: 120;
    }

    section {
        height: 100%;
    }

    .Button {
        margin-left: 20px;
        min-width: 193px;
        padding: 14px 20px;

        @include mobile {
            display: none;
        }
    }

    &-HeaderToggleIcon {
        align-items: center;
        background: $color-primary;
        border-radius: 2px;
        display: none;
        height: 24px;
        justify-content: center;
        width: 24px;

        svg {
            transition: all 0.15s ease-in;
        }

        &_isOpen svg {
            transform: rotate(-180deg);
        }

        @include mobile {
            display: inline-flex;
        }
    }

    &-Label {
        align-items: center;
        color: $color-gray9;
        display: flex;
        font-size: $font-size-p2;
        font-weight: $font-weight-bold;
        min-height: 36px;
        text-align: center;
        text-transform: uppercase;
    }

    &-Name {
        font-size: $font-size-h6;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        width: 100%;

        @include mobile {
            display: flex;
            justify-content: space-between;
        }
    }

    &-Price {
        font-size: $font-size-h6;
        font-weight: 600;

        &_type {
            &_chip {
                background-color: $green;
                border-radius: 50px;
                font-size: $font-size-p2;
                padding: 8px 10px;
            }

            &_primary {
                color: $color-blue4;
            }
        }
    }

    &-PriceSection {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 11px;
        justify-content: center;
        margin: 0 16px;
        min-width: $minWidthSection;

        &_type {
            &_chip {
                gap: 5px;
            }
        }

        @include divider;

        @include mobile {
            display: none;
            flex-direction: row;
            height: 38px;
            justify-content: space-between;
            width: 100%;

            &_isOpen {
                display: flex;
            }
        }
    }

    &-Summary {
        align-items: center;
        color: $color-gray6;
        display: flex;
        font-size: $font-size-p1;
        font-weight: $font-weight-bold;
        height: 52px;
        margin: 0 20px;
        @include divider;

        @include mobile {
            display: none;
        }
    }

    &-Wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        height: 100%;

        @include mobile {
            flex-direction: column;
        }
    }
}