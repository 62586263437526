/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.OfferActions {
    grid-area: actions;

    &-Wrapper {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
    }

    .Button {
        min-width: 195px;
        padding: 12px 25px;
    }

    @include mobile {
        width: 100%;
        display: flex;
        position: fixed;
        bottom: var(--footer-nav-height);
        box-shadow: 0px 1px 10px rgba(0, 51, 143, 0.2);
        z-index: 400;
        
        &-Wrapper {
            flex-direction: row;
        }
        
        .card {
            padding: 14px 16px;
            width: 100%;
        }

        .Button {
            min-width: unset;
        }
    }
}