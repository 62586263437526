/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.CarouselScrollItem {
    @include flex(null, null, null, ull);
    scroll-snap-align: start;
    border: none;
    transition: border-color var(--animation-speed),
        filter var(--animation-speed);
    filter: opacity(0.5);
    flex: 1;
    cursor: pointer;
    width: 90px;
    height: 85px;

    border-color: var(--option-border-color);
    border-radius: $light-border-radius;
    & + .CarouselScrollItem {
        margin-inline-start: var(--carousel-scroll-gap);
    }

    &_isActive {
        filter: opacity(1);
        border-color: var(--option-border-color);
    }

    &:focus,
    &:hover {
        filter: opacity(1);
    }
}
