/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

@mixin title {
    font-size: $font-size-h5;
    font-weight: 700;
    margin-top: 0;
    text-transform: none;
}

.OfferSettings {
    .card__body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 50px;
    }

    &-Actions {
        margin-top: 20px;

        input {
            bottom: 0;
            left: 0;
            opacity: 1;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
        }
    }

    .Button {
        padding: 14px 20px;
    }

    &-DeleteButton {
        cursor: pointer;
        height: 20px;
        position: absolute;
        right: 4px;
        top: 4px;
        width: 20px;

        svg {
            height: 20px;
            width: 20px;
        }
    }

    &-BusinessCondition {
        p {
            font-size: $font-size-p1;
        }

        &Section {
            grid-column: 1 / 3;

            button {
                margin-top: 20px;
            }

            input,
            textarea {
                width: 50%;
            }
        }
    }

    &-BusinessConditionActions {
        align-items: center;
        display: flex;
        gap: 14px;
        position: absolute;
        right: 0;
        top: 0;

        svg {
            height: 20px;
            width: 20px;
            cursor: pointer;
        }
    }

    &-BusinessConditionList {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr;

        @media (max-width: 1200px) {
            grid-template-columns: 1fr;
        }
    }

    &-BusinessConditionTitle {
        font-size: $font-size-p1;
        margin-top: 0;
    }

    &-Logo {
        align-items: center;
        border: 1px $color-gray4 solid;
        display: flex;
        height: 190px;
        justify-content: center;
        width: 190px;
    }

    &-Title {
        @include title;
    }

    &-LogoBlock {
        width: 50%;

        @include mobile {
            width: 100%;
        }
    }

    &-SwitchItem {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
        grid-gap: 15px;
        gap: 15px;
        font-size: $font-size-p2;
    }
}