/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --header-logo-width: 194px;
    --header-logo-height: 24px;

    --header-height: 80px;
    --header-nav-height: 60px;
    --header-total-height: calc(var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top));

    @include desktop {
        --header-top-menu-height: 32px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
    }
}

@mixin button-invisible {
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    width: 0;
    height: 0;
}

@mixin button-visible {
    opacity: 1;
    height: 25px;
    width: 25px;
    pointer-events: all;
    overflow: visible;
    cursor: pointer;
}

@mixin visible-on-mobile {
    @include button-visible;

    @include desktop {
        @include button-invisible;
    }
}

@mixin logo-visible {
    opacity: 1;
}

.hideOnOfferScroll {
    .Header {
        transform: translateY(-100%);
    }
}

.Header {
    align-items: center;
    background: transparent;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-block-start: env(safe-area-inset-top, 0);
    width: 100%;
    position: fixed;
    transition: all 0.3s;
    z-index: 105;
    inset-block-start: var(--demo-notice-height);

    >* {
        width: 100%;
    }

    &_menu,
    &_menu_subcategory {
        .Header-Button_type {
            &_close {
                @include desktop {
                    @include button-visible;
                }
            }

            &_menu {
                @include desktop {
                    @include button-invisible;
                }
            }
        }
    }

    &_name {

        &_menu,
        &_search,
        &_menu_subcategory {
            border-block-end: none;
        }

        &_category {
            position: fixed;

            @include mobile {
                border-block-end: none;
            }
        }
    }

    &-Button {
        @include button-invisible;

        &_isVisible {
            @include mobile {
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: 0.1;
        }

        &_type {

            &_menu,
            &_minicart {
                @include button-visible;

                @include desktop {
                    height: 24px;
                    margin-inline-start: auto;
                }
            }

            &_account {
                @include button-visible;
            }

            &_home {
                @include visible-on-mobile;
            }

            &_minicart {
                @include desktop {
                    margin-inline-start: 18px;
                }
            }

            &_back {
                .ChevronIcon {
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                }
            }

            &_close {
                .CloseIcon {
                    width: 27px;
                    height: 27px;
                }
            }

            &_share {
                @include button-visible;
            }

            &_compare {
                @include button-visible;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &-MinicartButtonWrapper {
        cursor: pointer;
    }

    &-CompareButtonWrapper {
        margin-inline-start: 18px;
        height: 24px;
    }

    &-LogoWrapper {
        inset-block-end: 0;
        height: var(--header-logo-height);
        inset-inline-start: 0;
        opacity: 0;
        overflow: hidden;
        inset-inline-end: 0;
        inset-block-start: 0;
        flex: 1;
        max-width: 300px;

        @include mobile {
            margin: auto;
        }

        @include desktop {
            @include logo-visible;
        }

        &_isVisible {
            @include logo-visible;
        }
    }

    &-Offer {
        &ButtonWrapper {
            display: flex;
            margin-inline-start: 18px;
        }

        &ItemCount {
            color: var(--primary-base-color);
            border-radius: 50%;
            background: var(--color-white);
            height: 17px;
            padding: 2px;
            position: absolute;
            width: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 80%;
            font-weight: 700;

            @include mobile {
                inset-block-start: -7px;
                inset-inline-end: -11px;
            }

            @include desktop {
                inset-block-start: -10px;
                inset-inline-end: -12px;
            }
        }
    }

    &-Minicart {
        &ButtonWrapper {
            height: 100%;
            width: 100%;
        }

        &ItemCount {
            background: var(--primary-base-color);
            border-radius: 50%;
            color: var(--color-white);
            height: 17px;
            padding: 2px;
            position: absolute;
            width: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 80%;
            font-weight: 700;

            @include mobile {
                inset-block-start: -7px;
                inset-inline-end: -11px;
            }

            @include desktop {
                inset-block-start: -10px;
                inset-inline-end: -12px;
            }
        }
    }

    &-Nav {
        height: 80px;
        background-color: var(--color-black);
    }

    &-NavInner {
        width: 100%;
        max-width: 1400px;
        padding: 0 32px;
        margin: 0 auto;
        @include flex(null, null, center, space-between);
    }

    &-MyAccount {
        @include desktop {
            height: 24px;
        }
    }

    &-News {
        align-items: center;
        display: flex;

        &Button {
            font-size: 12px;
            margin-inline-start: 5px;
        }

        .ExclamationMarkIcon {
            inset-block-end: 1px;
            height: 16px;
            margin-inline-end: 10px;
            width: 16px;
        }
    }

    &-Title {
        color: var(--header-color);
        margin: auto;
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        inset-inline-end: 0;
        text-align: center;
        text-overflow: ellipsis;
        inset-block-start: -1px;
        inset-inline-start: 0;
        inset-block-end: 0;
        transition-duration: 200ms;
        transition-property: opacity;
        white-space: nowrap;
        width: 100%;
        text-transform: inherit;
        line-height: 30px;
        height: 30px;

        &_isVisible {
            @include mobile {
                opacity: 1;
                max-width: calc(100% - 80px);
                color: $white;
                text-transform: uppercase;
                font-size: 18px;
                letter-spacing: 1px;
            }
        }
    }

    &-Welcome {
        display: none;
        font-size: 14px;
        margin-inline-end: 16px;
        margin: -2px 0 0 10px;
        text-align: end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
        align-self: center;
        line-height: 16px;

        @include wide-desktop {
            display: block;

            &_signedIn {
                @include flex(null, column, null, null);
            }
        }
    }

    &-TopMenu,
    &-Nav {
        display: flex;
    }

    &-TopMenu {
        justify-content: space-between;
        height: var(--header-top-menu-height);
        padding-inline: 32px;
        padding-block-start: 16px;
    }

    &-Switcher {
        align-items: center;
        display: flex;

        @include mobile {
            display: none;
        }

        h4 {
            color: #fff;
            padding-left: 16px;
            font-weight: bold;
        }
    }

    &_isCheckout {
        .Header {
            &-Button_type_account {
                display: block;
            }

            &-Button_type_close,
            &-Button_type_back {
                @include desktop {
                    display: none;
                }
            }

            &-LogoWrapper {
                margin-right: auto;
            }

            &-MyAccount {
                @include desktop {
                    margin-inline-start: auto;
                    height: 24px;
                }

                @include mobile {
                    position: absolute;
                    inset-inline-end: 10px;
                    width: auto;
                }
            }
        }

        .MyAccountOverlay {
            @include mobile {
                inset-block-start: var(--header-total-height);
                height: calc(100vh - var(--header-total-height));
                position: fixed;
                width: 100vw;
            }
        }
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
        margin-block-end: 12px;

        &_isPrerendered {
            @include mobile {

                .Header-TopMenu,
                .Menu-MenuWrapper {
                    display: none;
                }

                .Header-Nav {
                    &>* {
                        display: none;
                    }

                    .Header {

                        &-Title,
                        &-LogoWrapper,
                        &-Button {
                            &_isVisible {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    &_name_popup {
        z-index: 400;
    }

    &-MyAccountContainer {
        display: flex;
        margin-inline-start: 18px;
    }

    &-MyAccountWrapper {
        color: $color-gray4;
        cursor: pointer;
        @include flex();

        .Header-WelcomeHeading,
        .Header-WelcomeSubheading {
            color: $color-gray4;
            font-size: $font-size-p2;
            text-align: left;
            text-transform: uppercase;
            font-weight: 600;
        }

        &_signedIn {
            .Header-WelcomeHeading {
                text-transform: none;
                font-size: $font-size-p4;
                line-height: normal;
            }
        }
    }

    .Header-Button_type_back {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);

        svg {
            fill: #fff;
            color: #fff;

            path {
                color: #fff;
            }
        }
    }
}