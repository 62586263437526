/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.BlogPostsList {
    .ContentWrapper {
        max-width: var(--content-wrapper-width);
    }

    @include desktop {
        margin: 0;
    }

    &-Grid {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;

        @include mobile {
            display: block;
            flex-wrap: nowrap;
        }
    }

    &-CategoryName {
        text-align: center;
        vertical-align: middle;
        padding: 30px 0;
        background-color: var(--primary-base-color);

        h1 {
            color: white;
            text-transform: uppercase;
        }
    }

    &-Pagination {
        display: flex;
        justify-content: center;
        min-height: 56px;
        margin-inline: auto;
        margin-block-start: 40px;
        align-items: center;
        z-index: 2;
        width: min-content;
    
        @include desktop {
            order: 1;
        }
    
        @include mobile {
            padding-block-end: 0;
            display: none;
        }
    
        .ChevronIcon {
            cursor: pointer;
            display: block;
            height: 20px;
            width: 16px;
    
            &:hover,
            &:focus {
               fill: var(--primary-base-color);
            }
        }
    
        &-ListItem {
            margin-block-end: 0;
            padding-inline-start: 0;
            min-width: 35px;
            display: block;
    
            &::before {
                content: '';
            }
    
            &:first-child {
                @include mobile {
                    padding-inline-start: 0;
                }
            }
    
            &:last-child {
                margin-block-end: 0;
            }
        }
    }

    .Slider {
        overflow: hidden;
        height: auto;
        max-height: 428px;
        width: 100% !important;
    }

    .Slider-SliderWrapper * {
        max-height: 428px;
    }
}