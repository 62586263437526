/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "abstract/variables";
@import "abstract/mixins";
@import "../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "./abstract/abstract";

@import "../../node_modules/@scandipwa/scandipwa/src/style/base/breakpoints";
@import "../../node_modules/@scandipwa/scandipwa/src/style/base/keyframes";
@import "../../node_modules/@scandipwa/scandipwa/src/style/base/root";
@import "../../node_modules/@scandipwa/scandipwa/src/style/base/reset";
@import "./base/font";
@import "../../node_modules/@scandipwa/scandipwa/src/style/base/button";
@import "../../node_modules/@scandipwa/scandipwa/src/style/base/list";
@import "../../node_modules/@scandipwa/scandipwa/src/style/base/link";
@import "../../node_modules/@scandipwa/scandipwa/src/style/base/table";
@import "../../node_modules/@scandipwa/scandipwa/src/style/base/input";
@import "../../node_modules/@scandipwa/scandipwa/src/style/base/fieldset";
@import "../../node_modules/@scandipwa/scandipwa/src/style/base/blockquote";
@import "./cms/page/contact-page";
@import "./cms/page/trade-department";
@import "./cms/page/how-it-is-done-page";

@import "./cms/block";
@import "./base/input";
@import "./cms/page/cms-custom";
@import "./cms/page/inspiration-gallery-page";
@import "./cms/page/how-to-order-page";
@import "./cms/page/eko-page";
@import "./cms/page/landing-page";
@import "./cms/page/cms-landing-page";

h1 {
    text-transform: none;
}

main {
    margin-top: 0;
}

:root {
    --imported_h1_text_transform: none;
    --imported_h2_text_transform: none;
    --imported_h3_text_transform: none;
}
