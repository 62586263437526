// Additional variables probably rename

// BASE COLORS
$white: #fff;
$black: #1f1f1f;

$color-gray1: #f8f8f8;
$color-gray2: #f9f9f9;
$color-gray3: #f1f3fb;
$color-gray4: #e0e0e0;
$color-gray5: #e5e5e5;
$color-gray6: #5b5b5b;
$color-gray7: #3f3f3f;
$color-gray8: #262626;
$color-gray9: #979797;
$color-gray10: #b5b5b5;
$color-gray11: #f3f3f3;

$color-blue1: #84cfef;
$color-blue2: #009fe3;
$color-blue3: #0092d1;
$color-blue4: #006f9e;
$color-blue5: #00a0e3;
$color-blue6: #00b5da;

$color-red1: #ed4242;
$color-red2: #ff1612;
$color-red3: #e32900;
$color-red4: #e8442f;

$color-orange1: #e88d4c;
$color-orange2: #ff8d08;

$color-green1: #b6d97d;
$color-green2: #447c59;
$color-green3: #5D9601;
$color-green4: #f4ffe3;

$color-violet1: #6c7eab;
$color-violet2: #3e4076;
$color-violet3: #c8519b;
$color-violet4: #6d52bd;

// MAIN COLORS
$color-primary: $color-blue2;
$color-primary-light: $color-blue1;
$color-secondary: $color-gray5;
$color-secondary-light: $color-gray1;

// TEXT COLORS
$color-text-lightest: $color-gray2;
$color-text-light-dark: $color-gray6;
$color-text-medium-dark: $color-gray9;
$color-text-dark: $black;
$color-text-other: $color-blue2;

// LABEL COLORS
$color-label-paper: $color-blue3;
$color-label-set: $color-blue1;
$color-label-new: $color-red1;
$color-label-promotion: $color-blue4;
$color-label-sale: $color-red2;

//FONTS
$font-primary: "Averta CY", sans-serif;
$font-secondary: "Poppins", sans-serif;

// FONT-SIZES
$font-size-medium: 30px;

$font-size-h1: 48px;
$font-size-h2: 36px;
$font-size-h3: 26px;
$font-size-h4: 24px;
$font-size-h5: 20px;
$font-size-h6: 18px;

$font-size-p1: 16px;
$font-size-p2: 14px;
$font-size-p3: 12px;
$font-size-p4: 10px;

$secondary-font-size-h1: 42px;
$secondary-font-size-p3: 12px;

// LABELS
$light-border-radius: 2px;
$label-border-radius: 4px;
$review-border-radius: 10px;

//BORDER
$border-circe: 50%;

// FONT-WEIGHT
$font-weight-light: 400;
$font-weight-bold: 600;
$font-weight-bolder: 800;

// UNUSED
$default-primary-base-color: $color-text-other;
$default-primary-dark-color: darken($default-primary-base-color, 15%);
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$font-muli: "Muli", sans-serif;
$font-standard-size: 62.5%;