/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.TierPrices {
    width: 100%;
    margin-bottom: -10px;
    &-Item {
        font-size: $font-size-p2;
        padding-inline-start: 0;
        font-weight: $font-weight-light;
        &:last-child {
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }

        &::before {
            content: none;
        }

        &_isLowest {
            color: var(--secondary-dark-color);
            font-size: $font-size-p4;
            margin-block-end: 0;
            line-height: 1.8;
        }
    }

    &-ItemPrice {
        font-weight: $font-weight-bold;
    }
}
