/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.OfferShipping {
    .card {
        margin-bottom: 0;
    }

    &-Info {
        color: $color-gray6;
        font-size: 12px;
    }

    &-Input {
        border: 1px solid var(--input-border-color);
        font-size: 16px;
        font-weight: $font-weight-bold;
        height: auto;
        padding: 13px 26px 13px 10px;
        pointer-events: all;
        text-align: left;
        width: 105px;
    }

    &-HeaderContainer {
        position: relative;

        span {
            font-size: 16px;
            font-weight: $font-weight-bold;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}