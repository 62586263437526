/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../style/abstract/variables";
@import "../../../style/abstract/mixins";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.accordion {
    text-align: left;
    overflow: hidden;

    & > .accordion__item {
        & > .accordion__item__header {
            padding: 10px;
            background: #3f3f3f;
            border-radius: 2px;
            cursor: pointer;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #f9f9f9;
            position: relative;
            margin-bottom: 15px;

            .accordion__item__header__content__icon {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        & > .accordion__item__item-content {
            margin-bottom: 15px;
        }
    }
}
