/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --checkbox-border-radius: var(--imported_checkboxes_border_radius, 0);
    --checkbox-border-color: var(
        --imported_checkboxes_border_color,
        var(--primary-base-color)
    );
    --checkbox-border-color-active: var(
        --imported_checkboxes_border_color,
        var(--primary-base-color)
    );
    --checkbox-width: var(--imported_checkboxes_width, 18px);
    --checkbox-height: var(--imported_checkboxes_height, 18px);
    --required-text-color: #dc6d6d;
}

.Field {
    margin-block-start: 24px;

    @include mobile {
        margin-block-start: 14px;
    }

    &-Label {
        font-size: 12px;
        padding-block-end: 8px;

        &_isRequired {
            font-weight: bold;
            color: $color-red1 !important;
        }
    }

    &-LabelContainer {
        display: flex;
        align-items: center;

        .ChevronIcon {
            width: 23px;
        }
    }

    &-CheckboxLabel,
    &-RadioLabel {
        display: inline-flex;
        align-items: center;

        span {
            display: contents;
        }

        &:hover {
            cursor: pointer;
        }

        .input-control {
            @include mobile {
                min-width: 21px;
                min-height: 21px;
            }
        }
    }

    &-Error {
        &Messages {
            color: var(--primary-error-color);
            font-size: 12px;
            margin-block-end: -0.1em;
            margin-block-start: 6px;
        }
    }

    &-Message {
        color: var(--primary-error-color);
        font-size: 12px;
        margin-block-end: -0.1em;
        margin-block-start: 6px;
    }

    &-Note {
        margin-block-end: 2px;
        font-size: 12px;
        font-weight: 600;
        color: var(--field-note);
        opacity: 0;
        height: 0;
        transition: height 0.1s ease-in, opacity 0.1s linear;
        will-change: height, opacity;

        @include mobile {
            margin-block-end: 3px;
            font-size: 14px;
        }

        &::before {
            content: "* ";
        }
    }

    &_hasError {
        input:not([type="number"]),
        textarea,
        select {
            border: 1px solid var(--primary-error-color);
        }
    }

    input::placeholder {
        color: $color-gray9;
    }

    input:focus + .Field-Note {
        opacity: 1;
        height: 100%;
    }

    &_style {
        &_inline {
            $size-mobile: 20px;
            $size: 28px;
            $border-color: #000;
            $input-width-mobile: 35px;
            $input-width: 35px;

            &.Field {
                display: flex;
                margin: 0;

                button {
                    position: relative;
                    width: $size-mobile;
                    height: $size-mobile;
                    border: solid 1px $border-color;

                    @include desktop {
                        width: $size;
                        height: $size;
                    }

                    &:last-of-type {
                        order: -1;
                    }
                }
            }

            input {
                height: $size-mobile;
                min-height: $size-mobile;
                width: $input-width-mobile;
                min-width: $input-width-mobile;
                border: none;
                padding: 0;
                text-align: center;

                @include mobile {
                    font-size: 14px;
                }

                @include desktop {
                    height: $size;
                    min-height: $size;
                    width: $input-width;
                    min-width: $input-width;
                }

                &:focus {
                    border: none;
                }
            }
        }
    }

    &_type {
        &_checkbox,
        &_radio {
            label {
                &:first-of-type {
                    display: inline-flex;
                    vertical-align: middle;
                    max-width: 100%;
                }
            }

            input {
                width: 100%;
                height: 100%;
            }

            .input-control {
                vertical-align: middle;
            }
        }

        &_checkbox {
            label {
                &:first-of-type {
                    max-width: calc(100vw - 24px - 45px);

                    @include mobile {
                        max-width: calc(100vw - 28px - 45px);
                    }
                }
            }
        }

        &_number {
            align-items: center;
            display: inline-flex;

            .AddButton,
            .SubtractButton {
                width: 24px;
            }
        }

        &_text,
        &_textarea,
        &_email,
        &_password,
        &_date,
        &_time,
        &_datetime,
        &_datetime-local,
        &_file,
        &_select {
            &.Field_isValid {
                input,
                textarea,
                select {
                    border: 1px solid var(--primary-success-color);
                }
            }
        }

        &_file {
            &.Field {
                &_isValid {
                    input {
                        & + label {
                            border-color: var(--primary-success-color);
                        }
                    }
                }

                &_hasError {
                    input {
                        & + label {
                            border-color: var(--primary-error-color);
                        }
                    }
                }
            }
        }

        &_checkbox,
        &_radio {
            span {
                font-size: 12px;
            }
        }

        &_radio {
            margin: 0;
        }
    }

    select {
        width: 100%;
    }

    [type="checkbox"],
    [type="radio"] {
        position: absolute;
        opacity: 0;
        padding: 0;
        min-width: 0;
        min-height: 0;
        z-index: 10;
        cursor: pointer;

        &:disabled + .input-control {
            color: var(--color-dark-gray);
            cursor: auto;
        }

        &:hover + .input-control {
            cursor: pointer;

            @include desktop {
                --box-color: var(--secondary-light-color);

                border-color: var(--checkbox-border-color-active);

                &::after {
                    @include desktop {
                        --checkmark-color: var(--secondary-dark-color);
                    }
                }
            }
        }
    }

    [type="checkbox"] {
        + .input-control {
            border: 1px solid $color-gray10;
            border-radius: 4px;
            margin-right: 8px;
            @include size(18px);
        }

        &:checked {
            + .input-control {
                border-color: $color-blue2;
                background: $color-blue2;

                &::before {
                    content: "";
                    background: transparent
                        url("../../style/icons/checkmark.svg") center no-repeat;
                    background-size: contain;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -55%);
                    @include size(11px, 9px);
                }
            }
        }
    }

    [type="radio"] {
        + .input-control {
            border: 1px solid $color-gray10;
            border-radius: 50%;
            margin-right: 8px;
            @include size(18px);
        }

        &:checked {
            + .input-control {
                border-color: $color-blue2;

                &::before {
                    content: "";
                    background: $color-blue2;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    @include size(8px);
                }
            }
        }
    }

    [type="file"] {
        border-style: dashed;
        cursor: pointer;
        height: 180px;
        opacity: 0;
        max-width: 100%;
        z-index: 1;

        + label {
            align-items: center;
            border: 2px dashed var(--input-border-color);
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 180px;
            position: absolute;
            inset-block-start: 0;
            width: 100%;

            p {
                font-size: 16px;
                margin-block-end: 0;
            }

            span {
                color: var(--primary-base-color);
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
            }

            .UploadIcon {
                margin-block-end: 10px;
                height: 50px;
                width: auto;
            }

            + p {
                margin-block-start: 5px;
                margin-block-end: 0;
            }
        }
    }

    &-Labels {
        span {
            letter-spacing: 1px;
        }
    }

    &-SubLabel {
        font-weight: bold;
        font-size: 12px;
        white-space: break-spaces;
    }

    &-BottomText {
        font-size: 12px;
        line-height: 16px;
    }

    .password-show-hide {
        background: transparent
                        url("../../style/icons/eye-password-show.svg") center no-repeat;
        background-size: cover;
        margin-left: -30px;
        margin-top: 17px;
        position: absolute;
        z-index: 2;
        display: inline-block;
        width: 20px;
        height: 20px;
        cursor: pointer;

        &.show-password {
            background: transparent
            url("../../style/icons/eye-password-hide.svg") center no-repeat;
            background-size: cover;
        }
    }
}
