/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
     --breadcrumbs-height: 40px;
 }

.NoMatch {
    opacity: 0;
    animation: appear 100ms 300ms forwards;

    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
        margin-block-end: 0;
        margin-block-start: 0;
    }

    h1 {
        font-size: 144px;
        font-weight: 400;
        color: var(--primary-base-color);
        line-height: 1;

        @include mobile {
            font-size: 168px;
        }
    }

    &-Button {
        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 30px;
        }
    }

    &-Subtitle {
        font-size: 36px;
        font-weight: 700;
        margin: 0;
        line-height: 48px;

        @include mobile {
            font-size: 42px;
            line-height: 64px;

        }
    }

    &-Wrapper {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 20px;

        @include mobile {
            padding-inline: 16px;
        }

        img {
            @include desktop {
                max-height: 600px;
            }
        }
    }
}
