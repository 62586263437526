/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --search-criteria-background: var(--secondary-base-color);
    --search-criteria-label-color: rgba(10, 10, 10, 0.44);
    --search-criteria-search-term-color: #0a0a0a;
}

.SearchOverlay {
    &_isVisible {
        .SearchOverlay {
            &-Results {
                display: block;
                padding: 16px;
            }
        }
    }

    &-Results {
        background: var(--color-white);
        display: none;
        z-index: 1;
        width: 100%;

        @include desktop {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            padding-block-start: 20px;
            padding-block-end: 10px;
            padding-inline-start: 20px;
            padding-inline-end: 0;
            position: absolute;
            width: 100%;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
            top: 16px;

            ul {
                max-height: 400px;
                overflow-y: auto;
            }

        }
    }
    &-Loader {
        margin-top: 16px;
        padding: 20px 0;
    }
}
