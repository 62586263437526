.inspiration-gallery {
        h1, p, span {
            text-align: center !important;
            margin-top: 20px;
        }
        span {
            display: block;
        }

        h1 {
            margin: 0;
        }

    @include desktop {
        p, span, h1 {
            text-align: left !important;
        }

        p {
            margin-top: 40px;
        }
    
        span {
            display: block;
            margin-top: 25px;
        }
    }

    @include tablet {
        p, span, h1 {
            text-align: center !important;
        }
    }

    .stand-out {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px 0 20px;


        @include mobile {
            display: block;
            margin: 0;
        }
    }

    .description {
        flex: 1;
        margin-right: 100px;

        @include mobile {
            margin: 0;
        }
    }

    .wrapper, .full-color-wrapper, .grid-end {
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 100px;
        padding-top: 30px;
        border-top: 1px solid $color-gray9;
        margin-bottom: 30px;

        @include mobile {
            display: block;
        }

        @include tablet {
            display: block;
        }
    }

    .full-color-wrapper {
        margin-bottom: 0;
        .right-section {
            margin-top: 30px;

            @include desktop {
                margin-top: 60px;
            }

        }
    }

    .logo-gallery, .engraver-gallery {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;

        @include tablet {
            padding-top: 30px;
        }

        @include mobile {
            padding-top: 30px;
        }
    }
    
    .engraver-gallery {
        a:first-child {
            grid-column: 1/3;
        }
    }

    .color-print-image {
        width: auto !important;
        height: auto;
        padding-right: 30px;
    }

    .color-print-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: end;

        @include tablet {
            justify-content: center;
        }

        @include mobile {
            justify-content: center;
        }
    }

    .right-section {
        grid-column: 2/3;
        p {
            color: white !important;
            background-color: $black;
            width: 110px;
            padding: 0 5px;
            font-weight: 700 !important;
            letter-spacing: 1px;
            font-size: 30px;
        }
    }

    .image-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .grid-end {
        border: none;
        padding-top: 0;
        margin-bottom: 0;
    }

    .title {
        text-transform: uppercase;
        text-align: left !important;
    }
}