.TradeDepartment {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-Header {
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        color: $black;
        padding: 0;
        margin: 7px 0 46px;

        @include mobile {
            margin: 7px 0 26px;
         
        }
    }

    &-Details {
        display: grid;
        grid-column-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 25px 0 20px;
        border-top: 1px solid $color-gray4;
        border-bottom: 1px solid $color-gray4;

        @media (max-width: 599px) {
            display: flex;
            flex-direction: column;
            grid-column-gap: 0px;
        }

        @media (min-width: 600px) and (max-width: 1100px) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &-Address {
        display: flex;
        flex-direction: column;

        p, .Tel, .Email, .Fax {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: $black;
            margin: 0 0 4px;
            padding: 0;

            strong {
                margin-left: 5px;
                color: $black;
            }
        }

        span {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            margin-left: 34px;

            @include mobile {
                font-size: 16px;
                line-height: 22px;
            }
        }

        .Strong {
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: $black;
        }

        @include mobile {
            width: 100%;
            margin-bottom: 40px;

            p, .Tel, .Email, .Fax {
                font-size: 16px;
                line-height: 22px;
    
                .Strong {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }
    }

    .Tel, .Email, .Fax {
        display: inline-flex;
        align-items: center;
        &::before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 10px;
            background-repeat: no-repeat;
        }
    }

    .Tel {
        color: $color-gray6;

        &::before {
            background-image: url('../../icons/phone-call.svg');
        }
    }

    .Email {
        &::before {
            background-image: url('../../icons/mail.svg');
        }
    }

    .Fax {
        color: $color-gray6;

        &::before {
            background-image: url('../../icons/fax.svg');
        }
    }

    &-Contact {
        display: flex;
        flex-direction: column;

        .BoxRow {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        h4 {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            margin: 0 0 16px;
            padding: 0;
        }
    }

    &-Box {
        padding: 12px 15px 15px;
        background-color: $color-gray3;

        img {
            width: 60px;
            height: 60px;
        }
    }

    &-Languages {
        display: flex;
        align-items: center;
        margin-top: 22px;

        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $color-gray6;
            display: inline-block;
            margin-right: 38px;
        }

        p {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: $color-gray6;
            padding: 0;
            margin: 0;
        }
    }

    &-Content, .BoxRow {
        display: flex;
        align-items: center;
    }

    &-PersonDetails {
        display: flex;
        flex-direction: column;
        margin-left: 20px;

        span {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            display: block;
            margin-bottom: 4px;
        }

        a {
            text-decoration: none;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $color-gray6;
        }
    }

    &-Grid {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 20px 0;
        border-bottom: 1px solid $color-gray4;

        &.Last {
            border-bottom: none;
        }

        @media (max-width: 599px) {
            display: flex;
            flex-direction: column;
            grid-column-gap: 0px;
        }

        @media (min-width: 600px) and (max-width: 1100px) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &-BoxTitle {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: $color-gray6;
        display: block;
    }

    &-BoxCompanies {
        display: flex;
        padding-left: 20px;
        justify-content: space-between;
        padding-right: 20px;
        padding-bottom: 90px;
        flex-direction: row;
        margin-top: 50px;

        @include mobile {
            flex-direction: column;
            align-items: center;
            padding-bottom: 0px;
            margin-bottom: 60px;
            img {
                margin-bottom: 20px;
            }
        }

        img {
            width: auto;
        }
    }
}