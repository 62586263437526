@font-face {
    font-family: "Averta CY";
    src: url(../../../src/style/fonts/Averta_Cyrillic_300.woff) format("woff"),
        url(../../../src/style/fonts/Averta_Cyrillic_300.woff2) format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Averta CY";
    src: url(../../../src/style/fonts/Averta_Cyrillic_400.woff) format("woff"),
        url(../../../src/style/fonts/Averta_Cyrillic_400.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Averta CY";
    src: url(../../../src/style/fonts/Averta_Cyrillic_500.woff) format("woff"),
        url(../../../src/style/fonts/Averta_Cyrillic_500.woff2) format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Averta CY";
    src: url(../../../src/style/fonts/Averta_Cyrillic_600.woff) format("woff"),
        url(../../../src/style/fonts/Averta_Cyrillic_600.woff2) format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url(../../../src/style/fonts/Poppins-Thin.woff) format("woff"),
        url(../../../src/style/fonts/Poppins-Thin.woff2) format("woff2");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url(../../../src/style/fonts/Poppins-ExtraLight.woff) format("woff"),
        url(../../../src/style/fonts/Poppins-ExtraLight.woff2) format("woff2");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url(../../../src/style/fonts/Poppins-Light.woff) format("woff"),
        url(../../../src/style/fonts/Poppins-Light.woff2) format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url(../../../src/style/fonts/Poppins-Regular.woff) format("woff"),
        url(../../../src/style/fonts/Poppins-Regular.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url(../../../src/style/fonts/Poppins-Medium.woff) format("woff"),
        url(../../../src/style/fonts/Poppins-Medium.woff2) format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url(../../../src/style/fonts/Poppins-SemiBold.woff) format("woff"),
        url(../../../src/style/fonts/Poppins-SemiBold.woff2) format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url(../../../src/style/fonts/Poppins-Bold.woff) format("woff"),
        url(../../../src/style/fonts/Poppins-Bold.woff2) format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url(../../../src/style/fonts/Poppins-ExtraBold.woff) format("woff"),
        url(../../../src/style/fonts/Poppins-ExtraBold.woff2) format("woff2");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url(../../../src/style/fonts/Poppins-Black.woff) format("woff"),
        url(../../../src/style/fonts/Poppins-Black.woff2) format("woff2");
    font-weight: 900;
    font-style: normal;
}

//FONTS-DECLARATION
$font-primary: "Averta CY", sans-serif;
$font-poppins: "Poppins", sans-serif;

body {
    font-family: $font-primary;
}