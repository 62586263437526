/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.summary-card {
    height: 190px;
    justify-content: space-between;

    .card__header {
        z-index: 100;
        h2 {
            display: flex;
            align-items: flex-start;

            .label {
                margin-right: 10px;
            }
        }
    }

    .card__body {
        z-index: 100;
        font-weight: 700;
        font-size: 42px;
        line-height: 46px;
        color: #f9f9f9;
        padding-bottom: 30px;

        .summary-card__more {
            position: absolute;
            bottom: 0;
            left: 20px;

            a {
                color: #fff;
            }
        }
    }

    &.summary-card--type-percent {
        z-index: 50;
        &:after {
            display: block;
            position: absolute;
            right: -5px;
            bottom: -32px;
            content: "%";
            font-size: 140px;
            font-weight: bold;
            opacity: 0.1;
            color: #f9f9f9;
        }
    }

    .component-tooltip {
        position: relative;
        top: -12px;
    }

    .Loader {
        z-index: 99;
    }

    svg {
        circle {
            fill: #fff;
        }
        path {
            fill: #000;
        }
    }
}
