/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../style/abstract/variables";
@import "../../../style/abstract/mixins";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.grid-row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: stretch;

    width: 100%;
    margin-bottom: 14px;

    &:last-child {
        margin-bottom: 0;
    }

    .grid-col {
        display: flex;
        align-items: stretch;

        position: relative;
        margin-right: 7px;
        margin-left: 7px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:last-child,
        &:first-child {
            &.grid-col--size-md-1 {
                width: calc(#{(100% / 16) * 1} - 7px);
            }

            &.grid-col--size-md-2 {
                width: calc(#{(100% / 16) * 2} - 7px);
            }

            &.grid-col--size-md-3 {
                width: calc(#{(100% / 16) * 3} - 7px);
            }

            &.grid-col--size-md-4 {
                width: calc(#{(100% / 16) * 4} - 7px);
            }

            &.grid-col--size-md-5 {
                width: calc(#{(100% / 16) * 5} - 7px);
            }

            &.grid-col--size-md-6 {
                width: calc(#{(100% / 16) * 6} - 7px);
            }

            &.grid-col--size-md-7 {
                width: calc(#{(100% / 16) * 7} - 7px);
            }

            &.grid-col--size-md-8 {
                width: calc(#{(100% / 16) * 8} - 7px);
            }

            &.grid-col--size-md-9 {
                width: calc(#{(100% / 16) * 9} - 7px);
            }

            &.grid-col--size-md-10 {
                width: calc(#{(100% / 16) * 10} - 7px);
            }

            &.grid-col--size-md-11 {
                width: calc(#{(100% / 16) * 11} - 7px);
            }

            &.grid-col--size-md-12 {
                width: calc(#{(100% / 16) * 12} - 7px);
            }

            &.grid-col--size-md-13 {
                width: calc(#{(100% / 16) * 13} - 7px);
            }

            &.grid-col--size-md-14 {
                width: calc(#{(100% / 16) * 14} - 7px);
            }

            &.grid-col--size-md-15 {
                width: calc(#{(100% / 16) * 15} - 7px);
            }

            &.grid-col--size-md-16 {
                width: calc(#{(100% / 16) * 16} - 7px);
            }
        }

        & > div {
            width: 100%;
            height: 100%;
            min-height: 100%;
            position: relative;
        }

        &.grid-col--size-md-1 {
            width: calc(#{(100% / 16) * 1} - 14px);
        }

        &.grid-col--size-md-2 {
            width: calc(#{(100% / 16) * 2} - 14px);
        }

        &.grid-col--size-md-3 {
            width: calc(#{(100% / 16) * 3} - 14px);
        }

        &.grid-col--size-md-4 {
            width: calc(#{(100% / 16) * 4} - 14px);
        }

        &.grid-col--size-md-5 {
            width: calc(#{(100% / 16) * 5} - 14px);
        }

        &.grid-col--size-md-6 {
            width: calc(#{(100% / 16) * 6} - 14px);
        }

        &.grid-col--size-md-7 {
            width: calc(#{(100% / 16) * 7} - 14px);
        }

        &.grid-col--size-md-8 {
            width: calc(#{(100% / 16) * 8} - 14px);
        }

        &.grid-col--size-md-9 {
            width: calc(#{(100% / 16) * 9} - 14px);
        }

        &.grid-col--size-md-10 {
            width: calc(#{(100% / 16) * 10} - 14px);
        }

        &.grid-col--size-md-11 {
            width: calc(#{(100% / 16) * 11} - 14px);
        }

        &.grid-col--size-md-12 {
            width: calc(#{(100% / 16) * 12} - 14px);
        }

        &.grid-col--size-md-13 {
            width: calc(#{(100% / 16) * 13} - 14px);
        }

        &.grid-col--size-md-14 {
            width: calc(#{(100% / 16) * 14} - 14px);
        }

        &.grid-col--size-md-15 {
            width: calc(#{(100% / 16) * 15} - 14px);
        }

        &.grid-col--size-md-16 {
            width: calc(#{(100% / 16) * 16} - 14px);
        }

        &.grid-col--has-icons {
            text-align: center;

            svg {
                cursor: pointer;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

@include mobile {
    .grid-row {
        .grid-col {
            margin-bottom: 7px;

            &:last-child,
            &:first-child {
                &.grid-col--size-sm-0 {
                    width: 0;
                }

                &.grid-col--size-sm-1 {
                    width: calc(#{(100% / 16) * 1} - 7px);
                }

                &.grid-col--size-sm-2 {
                    width: calc(#{(100% / 16) * 2} - 7px);
                }

                &.grid-col--size-sm-3 {
                    width: calc(#{(100% / 16) * 3} - 7px);
                }

                &.grid-col--size-sm-4 {
                    width: calc(#{(100% / 16) * 4} - 7px);
                }

                &.grid-col--size-sm-5 {
                    width: calc(#{(100% / 16) * 5} - 7px);
                }

                &.grid-col--size-sm-6 {
                    width: calc(#{(100% / 16) * 6} - 7px);
                }

                &.grid-col--size-sm-7 {
                    width: calc(#{(100% / 16) * 7} - 7px);
                }

                &.grid-col--size-sm-8 {
                    width: calc(#{(100% / 16) * 8} - 7px);
                }

                &.grid-col--size-sm-9 {
                    width: calc(#{(100% / 16) * 9} - 7px);
                }

                &.grid-col--size-sm-10 {
                    width: calc(#{(100% / 16) * 10} - 7px);
                }

                &.grid-col--size-sm-11 {
                    width: calc(#{(100% / 16) * 11} - 7px);
                }

                &.grid-col--size-sm-12 {
                    width: calc(#{(100% / 16) * 12} - 7px);
                }

                &.grid-col--size-sm-13 {
                    width: calc(#{(100% / 16) * 13} - 7px);
                }

                &.grid-col--size-sm-14 {
                    width: calc(#{(100% / 16) * 14} - 7px);
                }

                &.grid-col--size-sm-15 {
                    width: calc(#{(100% / 16) * 15} - 7px);
                }

                &.grid-col--size-sm-16 {
                    width: calc(#{(100% / 16) * 16} - 7px);
                }
            }

            &.grid-col--size-sm-0 {
                width: 0;
                margin: 0;
            }

            &.grid-col--size-sm-1 {
                width: calc(#{(100% / 16) * 1} - 14px);
            }

            &.grid-col--size-sm-2 {
                width: calc(#{(100% / 16) * 2} - 14px);
            }

            &.grid-col--size-sm-3 {
                width: calc(#{(100% / 16) * 3} - 14px);
            }

            &.grid-col--size-sm-4 {
                width: calc(#{(100% / 16) * 4} - 14px);
            }

            &.grid-col--size-sm-5 {
                width: calc(#{(100% / 16) * 5} - 14px);
            }

            &.grid-col--size-sm-6 {
                width: calc(#{(100% / 16) * 6} - 14px);
            }

            &.grid-col--size-sm-7 {
                width: calc(#{(100% / 16) * 7} - 14px);
            }

            &.grid-col--size-sm-8 {
                width: calc(#{(100% / 16) * 8} - 14px);
            }

            &.grid-col--size-sm-9 {
                width: calc(#{(100% / 16) * 9} - 14px);
            }

            &.grid-col--size-sm-10 {
                width: calc(#{(100% / 16) * 10} - 14px);
            }

            &.grid-col--size-sm-11 {
                width: calc(#{(100% / 16) * 11} - 14px);
            }

            &.grid-col--size-sm-12 {
                width: calc(#{(100% / 16) * 12} - 14px);
            }

            &.grid-col--size-sm-13 {
                width: calc(#{(100% / 16) * 13} - 14px);
            }

            &.grid-col--size-sm-14 {
                width: calc(#{(100% / 16) * 14} - 14px);
            }

            &.grid-col--size-sm-15 {
                width: calc(#{(100% / 16) * 15} - 14px);
            }

            &.grid-col--size-sm-16 {
                width: calc(#{(100% / 16) * 16} - 14px);
            }
        }
    }
}
