/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.Pagination {
    &-List {
        display: flex;
        justify-content: center;
        list-style: none;
        width: 100%;

        &Item {
            margin-right: 15px;

            &Button {
                align-items: center;
                border: 1px solid var(--color-dark-gray);
                border-radius: 2px;
                cursor: pointer;
                display: flex;
                font-weight: 700;
                height: 36px;
                justify-content: center;
                padding: 7px 11px;
                width: 36px;

                &:hover {
                    color: var(--link-hover);

                    svg {
                        fill: var(--link-hover);
                    }
                }

                &_isActive {
                    border-color: $color-blue2;
                    color: $color-blue2;
                }
            }

            &:last-of-type {
                margin-right: 0;
            }

            &::before {
                display: none;
            }
        }
    }
}
