/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --footer-height: calc(
        var(--footer-content-height) + var(--footer-copyright-height)
    );
    --footer-content-height: 220px;
    --footer-copyright-height: 50px;
    --footer-nav-height: 48px;
    --footer-total-height: calc(
        env(safe-area-inset-bottom) + var(--footer-nav-height)
    );
}

.Footer {
    $column-count: 5;

    @include mobile {
        margin-block-end: var(--navigation-tabs-height);
    }

    &-Photo {
        @include mobile {
            display: none;
        }
    }

    &-CopyrightContentWrapper {
        background-color: #262626;
        color: #b5b5b5;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 12px;

        a {
            font-size: 12px;
            color: #b5b5b5;
            font-weight: 400;
        }
    }

    &-BottomFooter {
        height: 350px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        background-image: url("../../style/background/background-footer.png");
    }

    &-BottomFooterContent {
        @include mobile {
            padding: 0 13px;
        }
        padding-top: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-BottomFooterText {
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #f1f3fb;
        line-height: 24px;
        margin-top: 20px;
    }

    &-BottomFooterButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 13px 10px;
        width: 231px;
        height: 48px;
        font-size: 16px;
        background: #ffffff;
        flex: none;
        order: 2;
        flex-grow: 0;
        margin-top: 25px;
    }

    &-Br {
        display: none;
        @include mobile {
            display: block;
        }
    }

    &-BottomFooterQuestion {
        font-weight: 700;
        font-size: 42px;
        line-height: 46px;
        text-align: center;
        color: #f1f3fb;
        @include mobile {
            font-size: 30px;
            line-height: 1.2;
        }
    }

    @include mobile {
        &-Seo {
            margin: auto;
        }
    }

    &-CopyrightContent {
        padding-right: 32%;
        padding-left: 32%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        @media (max-width: 1450px) {
            padding-right: 26%;
            padding-left: 26%;
        }
        @media (max-width: 1070px) {
            padding-right: 20%;
            padding-left: 20%;
        }
        @include mobile {
            flex-direction: column;
            align-items: center;
            width: 100%;
            span {
                margin-bottom: 10px;
                text-align: center;
            }
            a {
                margin-top: 5px;
            }
        }
    }

    &-Column {
        input {
            display: none;
        }
    }

    @include mobile {
        &-Column {
            width: 100%;
            overflow: hidden;
            // :checked
            input:checked {
                + .Footer-ColumnTitle {
                    &::after {
                        transform: rotate(90deg);
                    }
                }

                ~ .Footer-ColumnContent {
                    max-height: 100vh;
                    padding-top: 1em;
                }
            }
        }

        &-ColumnContent {
            max-height: 0;
            padding: 0 1em;
            color: white;
            transition: all 0.35s;
        }

        &-ColumnTitle {
            display: flex;
            justify-content: space-between;
            padding: 0.7em;
            margin-bottom: -11px !important;
            font-weight: bold;
            cursor: pointer;
            font-size: 14px !important;
            @include mobile {
                padding: 0.7em 0;
            }

            &::after {
                content: "\276F";
                width: 1em;
                height: 1em;
                text-align: center;
                transition: all 0.35s;
            }
        }
    }

    &-Sociale {
        margin-top: 20px;
        margin-bottom: 50px;
        @include mobile {
            display: flex;
            justify-content: center;
            margin-bottom: 0px;
            a {
                margin-right: 5px !important;
                margin-left: 5px !important;
            }
        }

        a {
            margin-right: 20px;
        }
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: #1f1f1f;
        color: #b5b5b5;
    }

    &-Columns {
        display: flex;
        height: 100%;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 25px 16px;
        }

        @include desktop {
            padding-inline: 32px;
            padding-block-start: 50px;
            padding-bottom: 50px;
        }

        @include tablet {
            padding-inline: 30px;
        }

        &.ContentWrapper {
            > .Footer-Content {
                width: 100%;
            }
        }
    }

    &-Column {
        @include mobile {
            width: 100%;
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 30px;
            }

            @include desktop {
                padding-inline-end: 20px;
                max-width: calc(100% / #{$column-count - 1});
            }
        }
    }

    &-ColumnTitle {
        margin: 0;
        margin-block-end: 15px;
        color: #009fe3;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 600;
        @include desktop {
            margin-block-end: 10px;
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;

        i,
        a {
            font-size: 14px;
        }

        i {
            display: block;
            margin-top: 10px;
        }

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }
    }

    &-ColumnItem {
        color: inherit;
        margin-block-end: 6px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &:last-of-type {
            margin: 0;
        }

        &:hover {
            text-decoration: underline;
        }

        &Image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                // important required as default 100% width is passed as inline style
                // + you can't achieve same centering with just passing '16px' as inline style for the case
                width: 16px !important;
                inset-block-start: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);

                &:dir(rtl) {
                    transform: translate(50%, -50%);
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        &_type_image {
            width: 40px;
            height: 40px;
            border: 1px solid var(--secondary-dark-color);

            &:not(:last-of-type) {
                margin-inline-end: 20px;
            }
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
            @include mobile {
                width: 100%;
            }
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                padding-inline-start: 100px;
            }
        }
    }
}
