.LandingPage {

    .block {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 32px;
        //margin-block-start: calc(var(--header-total-height) + 12px);
        //margin-block-end: var(--header-nav-height);
        margin-inline: auto;
        padding: 0 20px;
    }

    .block::after {
        clear: both;
        content: "";
        display: table;
    }

    .bg-cover-transparent-3, .bg-cover-transparent-9 {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: #ffffff;
        position: absolute;
    }

    .bg-cover-transparent-3 {
        opacity: 0.3;
    }

    .bg-cover-transparent-9 {
        opacity: 0.9;
    }

    .banner-with-text {
        background: #ffffff;
        position: relative;

        h2 {
            text-align: center;
            margin: 30px 0;
        }

        .banner-image {
            background-position: center center;
            width: 100%;
            background-size: cover;
        }

        .text-container {
            top: 20%;
            left: 0;
            text-align: left;

            &.large {
                width: 80%;
            }

            h2 {
                color: white;
                font-size: 50px;
                line-height: 56px;
            }

            p {
                top: 8px;
                color: white;
                font-size: 23px;
                line-height: 32px;
                margin-top: 40px;

                @include mobile {
                    font-size: 18px;
                }
            }

            @include mobile {
                width: 100%;
                padding: 0 20px;
            }
        }

        @include mobile {
            img {
                height: 100%;
                background-size: cover;
                background-position: center center;
            }
        }

        &::before {
            background-size: cover;
            background-position: center center;
        }

        &.text-block {
            .banner-image {
                min-height: 500px;
            }

            .text-container {
                padding-top: 50px;
            }

            h2 {
                float: left;
                background: #000000;
                padding: 30px 50px;
            }
        }

        &.main-banner {

            &::before {
                opacity: 0.5;
            }

            p {
                color: #ffffff;
                font-weight: bold;
            }

            h2 {
                margin: 30px 0 !important;
            }
        }
    }

    .products-container {
        margin-bottom: 30px;
    }

    .products {
        margin-top: 40px;
        display: flex;

        .product {

            &:first-child {
                margin-left: 11%;
                margin-right: 2%;
            }

            &:last-child {
                margin-right: 11%;
            }

            text-align: center;

            width: calc((100% - 26%) / 2);
            display: inline-block;

            img {
                width: 100%;
                height: initial;
            }

            .name {
                margin-top: 14px;
                font-size: 20px
            }

            .type {
                font-size: 30px;
                text-transform: uppercase;
                margin: 0;
                padding: 22px;
            }

            p {
                line-height: 26px;
                margin-bottom: 25px;
            }

            @include mobile {
                margin: 15px 0;
                width: 100%;
            }

            @include tablet {
                width: calc((100% - 4%) / 2);
            }
        }
    }

    .gallery {
        padding: 10px 8% 30px 8%;
        margin-top: 35px;

        img {
            display: inline-block;
            margin-left: 2%;
            margin-top:10px;

            width: calc((100% - 10%) / 3);

            @include mobile {
                width: 100%;
                height: auto;
                margin: 10px 0;
            }

            @include tablet {
                width: calc((100% - 10%) / 2);
            }
        }

        .title-large {
            font-size:40px;
            text-align: center;
            padding: 20px 0 35px 0;
            font-weight: bold;
            text-transform: uppercase;

            @include mobile {
                font-size:32px;
            }
        }

        .title-small {
            font-size: 20px;
            text-align: center;
            line-height: 1.4;
        }

        @include mobile {
            padding: 10px 5%;
        }
    }

    .movie-box {
        width: 100%;
        background-color: #000000;
        padding: 30px 0 55px 0;
        color: #ffffff;

        h2 {
            text-align: center;
            color: #ffffff;
            font-size: 30px;
            margin-bottom: 45px;
        }

        .movie {
            display: flex;
            margin-bottom: 60px;

            iframe {
                max-width: 100%;
                margin: 0 auto;
            }
        }

        @include mobile {
            padding: 0;
        }
    }

    .columns {
        padding: 60px 120px;

        .one-column {
            width: 100%;
        }

        .column {
            float: left;
            width: 50%;
            padding: 0 30px;
        }

        .column, .one-column {
            h2 {
                margin: 70px 0;
                font-size: 44px;
                font-weight: bold;
                text-align: center;

                @include mobile {
                   margin: 10px 0 44px 0;
                }
            }

            h3 {
                font-size: 34px;
                font-weight: bold;

                &.title {
                    color: #ffffff;
                }
            }

            p {
                font-size: 18px;
                line-height: 28px;
            }

            ul {
                padding: 20px;
                padding-left: 50px;

                li {
                    font-size: 16px;
                    line-height: 1.7;
                    list-style-type: disc;
                    list-style-position: outside;
                    margin-bottom: 10px;

                    &::before {
                        content: "";
                    }
                }
            }

            .icons {
                width: 300px;
                margin: 40px auto;
            }

            @include mobile {
                float: none;
                width: 100%;
                padding: 0;
            }
        }

        .list {
            .icons-img {
                background-repeat: no-repeat;
                background-size: 50px 50px;
                background-position: left center;
            }

            p {
                padding-left: 120px;
                margin-top: 30px;
            }
        }

        &.bg-dark {
            h3 {
                font-size: 40px;
                margin: 35px 0 40px 0;
            }
        }
    }

    .bg-black {
        background-color: #000000;
    }

    .bg-dark {
        h2, p {
            color: #ffffff;
        }
    }

    .font-dark {
        h2, p {
            color: #000000 !important;
        }
    }

    .Button-Primary {
        text-decoration: none;
        padding: 13px 10px;
        margin: 16px auto;
        background-color: $color-blue2;
        color: $color-gray2;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        width: 219px;
        transition: 0.25s background-color ease-out;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
            width: 100%;
            max-width: 360px;
        }

        &:hover {
            background-color: $color-blue4;
        }
    }

    .bg-dark {
        .Button-Primary {
            margin: 50px 0 0 0;
        }
    }
}
