/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Slider {
    width: 100%;
    --translateX: 0;
    --translateY: 0;
    --animation-speed: 0;
    --slider-height: 600px;
    --slide-height: 0;
    --slide-width: 0;
    --height-transition-speed: 0;

    overflow: visible;

    max-width: 100%;

    touch-action: pan-y;

    transition: height var(--height-transition-speed);

    &-Dot {
        background: white;
        border: 1px $color-gray4 solid;
        border-radius: 50%;
        height: 10px;
        transition: all 0.3s ease-out;
        width: 10px;
        &_isActive {
            background: $color-primary;
            border: 1px $color-primary solid;
        }
    }

    &-Dots {
        bottom: 0;
        display: flex;
        gap: 6px;
        justify-content: center;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        @include desktop {
            display: none;
        }
    }

    &-SliderWrapper {
        overflow: hidden;
        height: 100%;
        @include mobile {
            a {
                width: 100% !important;
                margin-right: 0 !important;
                margin-left: 0 !important;
            }
        }

        &_isMainSliderInHomepage {
            height: auto;
            width: 100% !important;
            @include mobile {
                height: auto;
                a {
                    width: 100% !important;
                }
            }
        }
    }

    &-Wrapper {
        @include flex(null, null, flex-start, null);
        @include desktop {
            @include flex(null, null, flex-end, null);
        }
        height: 100%;
        max-width: 100%;

        will-change: transform;
        transition: transform var(--animation-speed);
        transform: translate3d(var(--translateX), var(--translateY), 0);

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
        }

        &_isVertical {
            @include flex(null, column, null, null);
        }
    }

    &-Image {
        @include flex(null, null, center, center);

        &_isActive {
            border-top: 6px solid #f9f9f9;
        }
    }

    &-Crumbs {
        margin-top: 0;
        height: 42px;
        background-color: #1f1f1f;
        position: absolute;
        width: 100%;
        inset-block-end: 12px;
        display: flex;
        justify-content: space-around;
        overflow: visible !important;
        @include mobile {
            display: none;
        }

        &::before {
            content: "";
            width: 999px;
            height: 100%;
            background-color: #1f1f1f;
            display: block;
            left: -999px;
            top: 0;
            z-index: 9;
            position: absolute;
        }

        &::after {
            content: "";
            width: 999px;
            height: 100%;
            background-color: #1f1f1f;
            display: block;
            right: -999px;
            top: 0;
            z-index: 9;
            position: absolute;
        }
    }

    &-Crumb {
        font-weight: 600;

        font-size: 14px;
        color: #f9f9f9;
        will-change: width, height;
        transition: width 300ms, height 300ms;
    }

    &-Arrow {
        cursor: pointer;
        position: absolute;
        inset-block-start: 50%;
        width: 48px;
        height: 44px;

        @include mobile {
            svg {
                width: 42px;
                height: 42px;
            }
        }

        .ChevronIcon {
            width: 100%;
            height: 100%;
        }

        &_isPrev {
            inset-inline-start: -9px;
            top: 45%;
            @include mobile {
                inset-inline-start: -11px;
                top: 51%;
            }

            &:dir(ltr) {
                transform: rotate(180deg) translateY(50%) scale(-1);
            }

            &:dir(rtl) {
                transform: translateY(50%);
            }
        }

        &_isNext {
            right: 27px;
            top: 45%;
            @include mobile {
                right: 0;
                top: 51%;
            }

            &:dir(ltr) {
                transform: translateY(-50%);
            }

            &:dir(rtl) {
                transform: rotate(180deg) translateY(-50%) scale(-1);
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }
}
