/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../style/abstract/variables";
@import "../../../style/abstract/mixins";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

$label-small-width: 20px;
$label-medium-width: 30px;
$label-large-width: 40px;
$button-ratio: 0.566666;
$button-size-small: calc(#{$label-small-width}*#{$button-ratio});
$button-size-medium: calc(#{$label-medium-width}*#{$button-ratio});
$button-size-large: calc(#{$label-large-width}*#{$button-ratio});

.switch {
    &-checkbox {
        height: 0;
        width: 0;
        display: none;
    }
    &-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        background: #e0e0e0;
        position: relative;
        transition: background-color 0.2s;
        .switch-button {
            content: "";
            position: absolute;
            left: 0px;
            transition: 0.2s;
            background: #b5b5b5;
            box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
        }
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    &.small {
        .switch-label {
            width: $label-small-width;
            height: calc(#{$label-small-width}/ 2);
            border-radius: $label-small-width;
            .switch-button {
                width: $button-size-small;
                height: $button-size-small;
                border-radius: $button-size-small;
            }
        }
    }
    &.medium {
        .switch-label {
            width: $label-medium-width;
            height: calc(#{$label-medium-width}/ 2);
            border-radius: $label-medium-width;
            .switch-button {
                width: $button-size-medium;
                height: $button-size-medium;
                border-radius: $button-size-medium;
            }
        }
    }
    &.large {
        .switch-label {
            width: $label-large-width;
            height: calc(#{$label-large-width}/ 2);
            border-radius: $label-large-width;
            .switch-button {
                width: $button-size-large;
                height: $button-size-large;
                border-radius: $button-size-large;
            }
        }
    }
}

.switch-checkbox:checked {
    + .switch-label {
        background: #84cfef;
        .switch-button {
            left: calc(100% + 2px);
            transform: translateX(-100%);
            background: linear-gradient(
                100.96deg,
                #009fe3 26.22%,
                #00b1fc 83.09%
            );
        }
    }
}

.switch-label:active .switch-button {
    &.small {
        width: calc((#{$label-small-width}*#{$button-ratio}) + 10px);
    }
    &.medium {
        width: calc((#{$label-small-width}*#{$button-ratio}) + 10px);
    }
    &.large {
        width: calc((#{$label-small-width}*#{$button-ratio}) + 10px);
    }
}
