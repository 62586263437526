/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
.ResetAttributes {
    @include desktop {
        margin-block-end: 16px;
    }

    @include mobile {
        margin: 16px;
        margin-block-end: 0;
    }

    &-AttributeValue {
        margin: 0 8px 4px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    &-AttributeText{
        font-size: 14px;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: $color-gray6;
    }

    &-CloseIcon {
        .CloseIcon {
            height: var(--checkbox-height);
            width: var(--checkbox-width);
            color: $color-gray6;
        }

    }

    &-Title {
        font-family: $font-primary;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: $black;
        padding: 8px 8px 8px 0;
        margin-bottom: 16px;
        display: flex;
        border-bottom: 1px solid $color-gray4;
        @include mobile {
            display: none;
        }

        @include desktop {
            margin-block-start: 0;
            text-transform: none;
        }
    }

    &-MobileTitle {
        @include mobile {
            padding-inline-start: 16px;
            font-weight: 700;
            padding-block-start: 20px;
        }

        @include desktop {
            display: none;
        }
    }
}
