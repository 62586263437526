/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 :root {
     --expandable-content-color: var(--color-karaka);
     --expandable-content-divider-color: var(--primary-divider-color);
     --toggle-button-size: 18px;
 }

 @mixin expanded-content {
     opacity: 1;
     max-height: 100%;
 }

.ExpandableFilter {
    @include mobile {
        border-block-start: 1px solid var(--expandable-content-divider-color);

        &:last-of-type {
            border-block-end: 1px solid va(--expandable-content-divider-color);
        }

        .AddIcon,
        .MinusIcon,
        .ChevronIcon {
            width: var(--toggle-button-size);
            height: var(--toggle-button-size);
            fill: $color-gray6;
        }
    }

    .AddIcon,
    .MinusIcon {
        width: var(--toggle-button-size);
        height: var(--toggle-button-size);
        margin-right: 3px;
    }

    .AddIcon { 
        fill: $black;

    }
    
    .MinusIcon {
        fill: $color-gray6;

    }
    .ChevronIcon {
        width: var(--toggle-button-size);
        height: var(--toggle-button-size);
    }

    &-Button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid$color-gray4;
        height: 35px;
        margin: 10px 0;
        @include mobile {
            padding-block: 14px;
        }
    }

    &-Heading,
    &-SubHeading {
        display: block;
        color: var(--expandable-content-color);
    }

    &-Heading {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        word-break: break-all;
        font-family: $font-primary;
        font-style: normal;

        @include mobile {
            font-size: 14px;
        }

        @include desktop {
            cursor: auto;
            font-size: 14px;
            font-weight: 600;
            font-family: $font-primary;
            text-transform: none;
        }
    }

    &-SubHeading {
        font-weight: 400;
        font-size: 10px;
        line-height: 1.1;
        @include mobile {
            font-size: 12px;
        }
    }

    &-Content {
        // Disabled as it prevents z-index use inside
        // will-change: max-height, padding, opacity;
        transition-property: opacity;
        transition-duration: 200ms;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
         

        @include desktop {
            margin-block-start: 12px;
        }

        &_isContentExpanded {
            @include expanded-content;
        }
    }

    @include mobile {
        &_isWithoutBorder {
            border: 0;

            &:last-of-type {
                border: 0;
            }
        }
    }
 }
