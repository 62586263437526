/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../style/abstract/variables";
@import "../../../style/abstract/mixins";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

:root {
    --popup-background: #0003;
    --popup-content-background: #fff;
    --popup-min-width: 50%;
    --popup-content-padding: 1rem;
}

.modal-container {
    z-index: 200;
    position: fixed;
    background-color: var(--popup-background);
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    opacity: 1;
    width: 100vw;
    height: 100vh;

    .modal-container__modal {
        background-color: var(--popup-content-background);
        border-radius: 5px;
        padding: var(--popup-content-padding);
        min-width: var(--popup-min-width);
        max-width: calc(var(--content-wrapper-width) * 0.8);
        max-height: 80vh;
        position: relative;

        .modal-header {
            margin-bottom: 19px;
            border-bottom: 1px solid #e1e2e1;
            padding-bottom: 19px;

            h1 {
                position: relative;

                .modal-header__caption {
                    font-size: 19px;
                    color: #0c0c0c;
                }

                .modal-header__icons {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;

                    .modal-header__icons__icon {
                        height: 100%;

                        svg {
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }

        .modal-body__outer {
            margin: 10px 0;
            overflow-y: auto;
            max-height: calc(80vh - 105px);

            .modal-body__inner {
                overflow-y: auto;
            }
        }
    }
}
