/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.Breadcrumb {
    $crumb-padding: 10px;

    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    padding-inline-end: $crumb-padding;
    margin-bottom: 13px !important;

    @include mobile {
        font-size: 14px;
    }

    span {
        color: $color-gray6;
    }

    &-Chevron {
        margin-left: 10px;
    }

    .ChevronIcon {
        filter: invert(57%) sepia(0%) saturate(1%) hue-rotate(105deg)
            brightness(93%) contrast(90%);
        display: inline-block;
        margin-inline-start: $crumb-padding;
        width: 12px;
        height: 12px;
        inset-block-end: -2px;
    }

    &:last-child {
        .ChevronIcon {
            display: none;
        }
        .Breadcrumb-Chevron {
            display: none;
        }
        :link {
            pointer-events: none;
        }
    }

    &::before {
        content: none;
    }

    &-Link {
        text-transform: unset;
        color: var(#5b5b5b);
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
    }
}

