/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../../style/abstract/variables";
@import "../../../style/abstract/mixins";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

:root {
    --button-padding: 12px 25px;
    --button-color-primary: #fff;
    --button-color-secondary: #009fe3;
    --button-background-color-primary: #009fe3;
    --button-background-color-secondary: transparent;
    --button-hover-color-color-primary: #fff;
    --button-border-width: 2px;
    --button-font-size: 14px;
    --button-border-radius: 3px;
    --button-background-color-disabled: #969696;
    --button-font-weight: 700;
}

.button {
    text-decoration: none;
    display: inline-flex;
    border-radius: var(--button-border-radius);
    font-weight: 500;
    font-size: var(--button-font-size);
    text-transform: uppercase;
    padding: var(--button-padding);
    transition-property: background-color, color;
    will-change: background-color, color;
    transition-timing-function: ease-out;
    transition-duration: 0.25s;
    cursor: pointer;
    text-align: center;
    position: relative;
    margin-right: 25px;
    align-items: center;

    &.button--color-primary {
        background-color: var(--button-background-color-primary);
        border: var(--button-border-width) solid
            var(--button-background-color-primary);

        .button__caption-wrapper {
            color: var(--button-color-primary);
            font-weight: var(--button-font-weight);
        }

        &:hover {
            background-color: darken($color-blue2, 10%);
        }

        &.button--is-outlined {
            border: 2px solid var(--button-background);
            background-color: transparent;

            .button__caption-wrapper {
                color: var(--button-background-color-primary);
            }

            &:hover {
                background-color: var(--button-hover-background-color-primary);
                color: var(--button-hover-color-color-primary);

                .button__icon-wrapper .button__icon-wrapper__inner svg path {
                    fill: var(--button-hover-color-color-primary);
                }
            }
        }
    }

    &.button--color-secondary {
        background-color: var(--button-background-color-secondary);
        border: var(--button-border-width) solid
            var(--button-background-color-secondary);

        .button__caption-wrapper {
            color: var(--button-color-secondary);
            font-weight: var(--button-font-weight);
            margin: 0 5px;
        }

        &:hover {
            border: var(--button-border-width) solid
                var(--button-color-secondary);
        }
    }

    &.button--is-block {
        width: 100%;
        .button__caption-wrapper {
            width: 100%;
            text-align: center;
        }
    }

    &.button--is-disabled {
        cursor: auto;
        background-color: var(--button-background-color-disabled) !important;
        border-color: var(--button-background-color-disabled) !important;

        .button__caption-wrapper {
            color: #fff !important;
        }
    }

    .button__icon-wrapper,
    .button__caption-wrapper {
        position: relative;
        display: inline-block;
        margin-right: 5px;
    }

    .button__icon-wrapper {
        width: 24px;
        height: 24px;
        margin-right: 9px;

        .button__icon-wrapper__inner {
            svg path {
                transition-property: fill;
                will-change: fill;
                transition-timing-function: ease-out;
                transition-duration: 0.25s;
            }
        }
    }

    &.button--size-sm {
        padding: 5px;
    }
}
