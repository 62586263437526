/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.RMAForm {
    input {
        width: 100%;
    }

    &-RequiredFields {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $color-gray6;

        p {
            color: $color-red1;
            padding: 0 4px 0 0;
            margin: 0;
            display: inline-block;
        }
    }

    &-Legend {
        min-width: 0;
        legend {
            padding: 8px 0;
        }
        h3 {
            border-bottom: 1px solid $color-gray9;
            padding-bottom: 8px;
            font-size: 20px;
            text-transform: none;
            margin-bottom: 0;
        }
        &-Sublegend {
            font-size: 16px;
            font-weight: 400;
            font-style: italic;
            color: $color-gray6;
        }
        .Field {
            &-Label {
                font-size: 14px;
                font-weight: 500;
                color: $color-gray6;
            }
            &-CheckboxLabel {
                font-size: 14px;
                text-transform: lowercase;
                color: $color-gray6;
            }
        }
        p {
            margin: 10px 0;
            font-size: 16px;
        }
        .AttachmentGallery {
            margin-bottom: 0;

            &-DropZone {
                margin-bottom: 0;
            }
        }
    }

    &-ProductsLegend {
        display: flex;
        justify-content: space-between;
    }
    
    &-Regulations {
        ol {
            margin: 20px 0;

            li {
                margin: 0;
                font-size: 12px;
                color: $color-gray6;
                list-style-type: decimal;
                &::before {
                    content: '';
                }
            }
            &.lower-alpha {
                li {
                    list-style-type: lower-alpha;
                }
            }
            p {
                margin-bottom: 10px;
                color: $color-gray6;
                font-size: 12px;
            }
        }
    }

    &-SendRequest {
        width: 100%;
        margin-block-start: 24px;
        margin: 30px 0;

        @include mobile {
            width: 100%;
            margin-block-end: 18px;
        }
    }

    &-DoubleInput {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 16px;
        }
    }
    
    &-TripleInput {
        @include desktop {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 16px;
        }
    }

    &-ProductLine {
        border: 1px solid $color-gray5;
        padding: 10px 10px 10px 10px;
        box-shadow: 0px 1px 6px rgba(0, 51, 143, 0.1);
        border-radius: 4px;
        margin-top: 20px;

        @include desktop {

        }
    }

    .AddressSelect {
        margin-top: 20px;
    }

    &-AddPlusIcon {
        height: 24px;
        width: 24px;
    }

    &-NewSubject {
        align-items: center;
        color: $color-primary;
        display: flex;
        font-size: $font-size-p1;
        font-weight: 600;
        gap: 6px;
        line-height: 22px;

        &:hover {
            color: var(--link-hover);

            & .RMAForm-AddPlusIcon {
                circle {
                    fill: var(--link-hover);
                }
            }

            cursor: pointer;
        }
    }

    &-RemoveSubjectWrapper {
        border-top: 1px solid $color-gray5;
        margin-top: 10px;
        padding: 10px 0;
    }

    &-RemoveSubject {
        align-items: center;
        color: $color-red1;
        display: flex;
        font-size: $font-size-p1;
        font-weight: 600;
        gap: 6px;
        line-height: 22px;

        &:hover {
            color: var(--link-hover);

            & .RMAForm-TrashIcon {
                circle {
                    fill: var(--link-hover);
                }
            }

            cursor: pointer;
        }
    }

    textarea {
        color: $color-gray9;
        min-height: 148px;
        width: 100%;
    }

    &-CustomAddress {
        border: 1px solid $color-gray5;
        padding: 0px 10px 20px 10px;
        box-shadow: 0px 1px 6px rgba(0, 51, 143, 0.1);
        border-radius: 4px;
    }

    &-File {
        background: $color-gray3;
        width: 100%;
        margin: 10px 0 0 0 !important; // override vendor li
        position: relative;
        padding: 10px 30px 10px 10px;

        &::before {
            display: none;
        }

        > svg {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            padding: 5px;
            box-sizing: content-box;
        }
    }

    &-FileInput {
        display: none;
    }

    &-FilesUploadWrapper {
        margin-top: 10px;
        @include flex(null, null, center, null);

        .Button {
            min-width: 150px;
        }

        @include mobile {
            flex-direction: column;
        }
    }

    &-FilesUploadLabel {
        margin-left: 15px;
        font-style: italic;
        font-size: $font-size-p2;

        @include mobile {
            margin: 10px 0 0;
            text-align: center;
        }
    }

    &-FileErrorMessage {
        color: var(--primary-error-color);
        font-size: 12px;
        margin-block-end: -0.1em;
        margin-block-start: 6px;
    }
}

.CheckoutPayment {
    @include tablet {
        width: 100%;
    }

    &::before {
        content: none;
    }

    &-Button {
        --check-mark-opacity: 0;
        width: 100%;
        display: flex;
        align-items: flex-start;
        font-size: $font-size-p1;
        cursor: pointer;
    }
}
