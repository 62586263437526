/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ImageZoomPopup {
    height: 100%;

    &.Popup {
        height: 100%;
    }

    &-PopupContent.Popup-Content {
        max-height: 100vh;
        height: 100vh;
        width: 100%;
        overflow-x: hidden;
        padding: 0;

        .Popup-CloseBtn {
            inset-inline-end: 10px;
            inset-block-start: 10px;
        }
    }

    .ProductGallery {
        height: 100%;

        &-SliderWrapper {
            height: 100%;
        }
    }

    .Slider-Arrow {
        display: none;
    }
}
