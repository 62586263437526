/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.LabelsImage {
    &-CircleLabels {
        height: 35px;
        z-index: 2;
        @include flex(null, null, null, flex-end);
        position: absolute;
    }

    &-TextLabels {
        z-index: 2;
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        position: absolute;
        &_card {
            .label {
                font-size: $font-size-p3;

                @include mobile {
                    margin-bottom: 0;
                }
            }
        }
        .label {
            margin-right: 0;
        }
    }

    &-NewIcon {
        margin-right: 5px;
        text-transform: uppercase;
        color: $white;
        padding: 4px;
        font-size: $font-size-p3;
        background-color: $color-red1;
        font-weight: 500;
        border-radius: $label-border-radius;
    }

    &-PromotionIcon {
        text-transform: uppercase;
        color: $white;
        padding: 4px;
        font-size: $font-size-p3;
        background-color: $color-blue4;
        font-weight: 500;
        border-radius: $label-border-radius;
    }
}
