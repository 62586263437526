/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.HomePage {
    width: 100%;
    overflow-x: hidden;
    margin-top: -20px;
    @include mobile {
        margin-top: -55px;
    }

    &-PrevArticle {
        font-weight: 700;
        font-size: 15px;
        line-height: 16px;
        text-transform: uppercase;
        color: #009fe3;
        margin-bottom: -5px;
        text-align: right;
    }

    .CmsPage {
        &,
        &-Wrapper {
            margin-block-end: 0;
        }
    }

    &-FullWidth {
        margin-top: 60px;
        margin-bottom: 60px;
        @include mobile {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    &-FullWidthTitle {
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        color: #5b5b5b;
    }

    &-FullWidthFigcaption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding-bottom: 45px;
        padding-left: 26px;
        padding-right: 16px;
        align-items: flex-start;
        position: absolute;
        inset-block-end: 24px;
        text-align: center;
    }

    &-FullWidthButton {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    svg {
        margin-left: 9px;
    }

    &-Wrapper {
        width: 100vw;
    }

    &-WrapperChild {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        padding-left: 32px;
        padding-right: 32px;
        margin-top: calc(var(--header-total-height) + 12px);
        margin-bottom: var(--header-nav-height);
        margin-left: auto;
        margin-right: auto;
    }

    &-BoxCompanies {
        display: flex;
        padding-left: 20px;
        justify-content: space-between;
        padding-right: 20px;
        padding-bottom: 90px;
        flex-direction: row;
        @include mobile {
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: center;
            padding-bottom: 0px;
            img {
                margin-bottom: 20px;
            }
        }

        img {
            width: auto;
        }
    }

    &-FullWidth {
        width: 100%;
    }

    &-FullWidthImg {
        display: block;

        &_isMobile {
            display: none;
            @include mobile {
                display: block;
            }
        }

        @include mobile {
            display: none;
        }
    }

    &-BoxesSectionLine {
        width: 68%;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 32%;
        border-top: 1px solid transparent;
        @include mobile {
            width: 100%;
            margin-left: 0;
        }
    }

    &-BoxesSection {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: [col1-start] 30% [col2-start] 33.5% [col3-start] 33.5% [col3-end];
        grid-template-rows: [row1-start] auto [row2-start] auto [row2-end];
        padding-bottom: 10px;
        @include mobile {
            display: block;
        }

        img {
            grid-column: col3-start;
            grid-row: row2-start;
            object-fit: cover;
            width: 100%;
        }
    }

    &-SliderTwoPhotos {
        grid-column: col2-start / col3-end;
        grid-row: row1-start;
    }

    &-BoxesSectionLeftImg {
        grid-column: col1-start;
        grid-row: row1-start / row2-end;

        @include mobile {
            height: 500px;
        }

        .RenderWhenVisible {
            height: 100%;
        }

        .Slider {
            height: 100%;
        }
        
        .SliderWidget-Figure {
            height: 100%;
        }
    }

    &-BoxesSectionRight {
        width: 100%;
        margin-left: 15px;
    }

    &-BoxFacilities {
        padding: 22px;
        border: 1px solid #e0e0e0;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        margin-top: 22px;

        @include mobile {
            display: block;
            padding: 15px;
            font-size: 12px;
            svg {
                margin-left: 0;
                width: 33px;
            }
        }
    }

    &-ContentBoxTitle {
        font-weight: 600;
        font-size: 16px;
        margin-top: 9px;
        margin-bottom: 9px;
    }

    &-ContentBoxText {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #5b5b5b;
        @include mobile {
            font-size: 12px;
            line-height: 19px;
        }
    }

    &-ThreeBoxesLinks {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        a {
            width: 33%;
        }

        @include mobile {
            flex-direction: column;
            width: 100%;
            margin-top: 20px;

            a {
                width: 100%;
                margin-bottom: 7px;
            }
        }
    }

    &-ContentBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 0 10px 10px;
        grid-column: col2-start;
        grid-row: row2-start;

        svg {
            margin-left: 0;
        }

        @include mobile {
            margin-top: 20px;
        }
    }

    &-ContentBoxImg {
        grid-column: col3-start;
        grid-row: row2-start;
    }

    &-BoxFacility {
        display: flex;
        align-items: center;
        @include mobile {
            margin-bottom: 5px;
        }
    }

    &-ContentBoxFacility {
        margin-left: 9px;
        @include mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &-FacilityTitle {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 5px;
        @include mobile {
            font-size: 14px;
            margin-bottom: 0;
        }
    }

    &.HomePage_isMobile {
        margin-top: 80px;
    }
}
