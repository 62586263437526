/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

body {
    :has(.OfferPage) {
        background: $color-gray3;
    }
}

.Breadcrumbs:has(+ .OfferPage) {
    padding-top: 80px;
}

.Breadcrumbs~.OfferPage {
    .Breadcrumbs {
        padding-top: 40px;
    }
}

.Header-Wrapper:has(~.OfferPage) {
    @include mobile {
        display: none;
    }
}

.OfferPage {
    background: $color-gray3;
    margin-top: -12px;
    padding-top: 1px;

    .OfferMarkUp {
        margin-top: 8px;
    }

    &-Wrapper {
        display: grid;
        gap: 20px;
        grid-template-areas:
            "markUp markUp"
            "products products"
            "details summary"
            "details shipping"
            "address address"
            "actions actions"
            "navigation navigation";
        margin-bottom: 20px;

        @include mobile {
            grid-template-areas: "markUp"
                "products"
                "details"
                "summary"
                "shipping"
                "address"
                "actions"
                "navigation";
        }
    }

    &-NotLoggedIn {
        font-size: $font-size-h4;
        padding: 50px 0;
        text-align: center;
    }

    .ProductCard-CatalogPrice {
        justify-content: left;
    }

    .card {
        margin-bottom: 0;
    }
}