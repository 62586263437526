/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.OfferBottomNavigation {
    display: grid;
    gap: 20px;
    grid-area: navigation;
    grid-template-columns: repeat(3, 1fr);

    @include mobile {
        display: flex;
        flex-direction: column;
    }

    &-Button {
        align-items: center;
        background: $color-gray1;
        border-radius: 4px;
        box-shadow: 0px 1px 6px rgba(0, 51, 143, 0.1);
        cursor: pointer;
        display: flex;
        font-size: $font-size-p1;
        font-weight: $font-weight-bold;
        gap: 10px;
        height: 79px;
        padding: 20px;
        transition: background 0.15s ease-in;
        color: $black;

        &:hover {
            color: $black;
            background: $color-gray11;
        }

        &:last-of-type {
            svg {
                height: 24px;
                width: 24px;
            }
        }

        svg {
            height: 20px;
            width: 20px;
        }

        path {
            stroke: $black;
        }
    }
}