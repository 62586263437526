/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/mixins";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons";
@import "../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts";

.OfferDetails {
    grid-area: details;

    select {
        border: 1px solid var(--input-border-color) !important;
    }

    textarea {
        border: 1px solid var(--input-border-color) !important;
        color: $color-gray9;
        margin-top: -14px;
        min-height: 116px;
        width: 100%;
    }

    .card {
        margin-bottom: 0;

        &__header {
            padding-bottom: 11px;
        }
    }

    &-Conditions {
        color: $color-gray10;
    }

    &-FieldsGroup {
        display: grid;
        gap: 20px;
        grid-template-areas: "name name"
            "validFrom validTo"
            "conditions conditions";
        max-width: 440px;

        .Field {
            margin-top: 0;
        }
    }

    &-Input[type="date"]::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        background-image: url("../../assets/icons/calendar.svg");
        background-repeat: no-repeat;
        background-position: 100% center;
        background-size: 20px;
        cursor: pointer;
        position: absolute;
        right: 10px;
        width: 24px;
    }

    &-InputLabel {
        color: $color-gray6;
        display: flex;
        flex-direction: column;
        font-size: $font-size-p2;
        font-weight: $font-weight-bold;
        gap: 6px;
        user-select: none;
        width: 100%;

        & input {
            border: 1px solid var(--input-border-color) !important;
            width: 100%;
        }

        &:nth-child(1) {
            grid-area: name;
            width: 100%;
        }

        &:nth-child(2) {
            grid-area: validFrom;
            width: 100%;
        }

        &:nth-child(3) {
            grid-area: validTo;
            width: 100%;
        }

        &:nth-child(4) {
            grid-area: conditions;
            width: 100%;
        }

        &:nth-child(5) {
            grid-area: conditions;
        }

        .Field_type_select {
            margin-top: 0;

            .ChevronIcon {
                fill: $black;
            }
        }
    }
}